import * as types from "./DataManagementActions";

export const initialState = {
  isLoading: false,
  result: undefined,
  requestType : undefined,
  errorMessage: undefined
};

const DataVisualisationReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_DATA_REQUEST:
            return Object.assign({}, state, {
                isLoading: true,
                result: undefined,
                errorMessage: undefined,
                requestType:undefined
            });
        case types.FETCH_DATA_SUCCESS:
            return Object.assign({}, state, {
                result: action.result,
                isLoading: false,
                requestType: action.requestType
            });
        case types.FETCH_DATA_FAILURE:
            return Object.assign({}, state, {
                isLoading: false,
                errorMessage: action.errorMessage,
            });
        default:
            return state;
    }
}

export default DataVisualisationReducer;
