import * as types from "./ServerManagementActions";

export const initialState = {
  isLoading: false,
  result: undefined,
  errorMessage: undefined
};

const ServerManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_ACTUATORS_REQUEST:
            return Object.assign({}, state, {
                isLoading: true,
                result: undefined,
                errorMessage: undefined
            });
        case types.FETCH_ACTUATORS_SUCCESS:
            return Object.assign({}, state, {
                result: action.result,
                isLoading: false,
                errorMessage: undefined
            });
        case types.FETCH_ACTUATORS_FAILURE:
            return Object.assign({}, state, {
                isLoading: false,
                errorMessage: action.errorMessage
            });
        default:
            return state;
    }
}

export default ServerManagementReducer;
