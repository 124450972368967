import * as types from "./AttendanceCertificatesActions";

export const initialState = {
    isLoading: false,
    attendanceCertificates: undefined,
    errorMessage: undefined,
    stats: undefined
};

const AttendanceCertificatesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SEND_CREATEATTENDANCECERTIFICATES_REQUEST:
            return Object.assign({}, state, {
                isLoading: true,
                errorMessage: undefined,
                attendanceCertificates: undefined
            });
        case types.SEND_CREATEATTENDANCECERTIFICATES_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                attendanceCertificates: action.attendanceCertificates
            });
        case types.SEND_CREATEATTENDANCECERTIFICATES_FAILURE:
            return Object.assign({}, state, {
                isLoading: false,
                errorMessage: action.errorMessage
            });
        case types.FETCH_ABSENCESSTATSFORCOURSEYEARGROUPINPERIOD_REQUEST:
            return Object.assign({}, state, {
                isLoading: true,
                errorMessage: undefined,
                stats: undefined
            });
        case types.FETCH_ABSENCESSTATSFORCOURSEYEARGROUPINPERIOD_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                stats: action.stats
            });
        case types.FETCH_ABSENCESSTATSFORCOURSEYEARGROUPINPERIOD_FAILURE:
            return Object.assign({}, state, {
                isLoading: false,
                errorMessage: action.errorMessage
            });
        default:
            return state;
    }
}

export default AttendanceCertificatesReducer;
