import React, { useState } from "react";

import Alert from '@mui/material/Alert';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { ActionableDialog } from '../../../../shared/components';

export function DeleteAbsenceModal(props) {
    const [hasSentRequest, setHasSentRequest] = useState(false);
    const [selectedMotive, setSelectedMotive] = useState('');

    const getContent = () => {
        return {
            title: "Supprimer des absences",
            text: getSelect()
        }
    }

    const handleChange = (e) => {
        setSelectedMotive(e.target.value);
    }

    const handleSendRequest = () => {
        let absenceIds = [];
        if (props.data[0].absences) {
            absenceIds = props.data.flatMap(row => row.absences).map(absence => absence.absenceId);
        } else {
            absenceIds = props.data.map(absence => absence.id);
        }

        if (absenceIds) {
            setHasSentRequest(true);
            props.sendRequest(absenceIds, selectedMotive);
        }
    }
    
    const getSelect = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', maxWidth: '500px'}}>
                <Typography>
                    Veuillez sélectionner un motif pour la suppression des absences, puis confirmer:
                </Typography>
                <FormControl variant="standard" sx={{ minWidth: 400 }}>
                    <InputLabel id="motive-type-select-label">Motif de la suppression</InputLabel>
                    <Select
                        labelId="motive-type-select-label"
                        id="motive-type-select"
                        value={selectedMotive}
                        onChange={handleChange}
                        label="Raison de la suppression"
                    >
                        { props.motiveTypes ?
                            Object.entries(props.motiveTypes).sort(sortMotives).map(([motiveType, description], index) => {
                                if (motiveType.startsWith("ERROR")) {
                                    return (
                                        <MenuItem key={index} value={motiveType}>
                                            {description}
                                        </MenuItem>
                                    );
                                }
                                return null;                          
                            })
                            : null
                        }
                    </Select>
                </FormControl>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    disabled={!selectedMotive || props.success || props.failure}
                    onClick={handleSendRequest}
                    loading={props.isLoading}
                >
                    Supprimer
                </LoadingButton>
                { props.success
                    ? <Alert severity="success">Les absences ont bien été supprimées.</Alert>
                    : null
                }
                { props.failure
                    ? <Alert severity="error">Une erreur s'est produite, veuillez contacter un administrateur à admin@alumneo.fr.</Alert>
                    : null
                }
            </div>
        );
    }

    return (
        <ActionableDialog
            open={props.open}
            onClose={() => props.onClose(hasSentRequest)}
            content={getContent()}
        />
    );
}

const sortMotives = ([motiveType1, description1], [motiveType2, description2]) => {
    return description1 > description2;
}