export const FETCH_ABSENCESSTATSFORCOURSEYEARGROUPINPERIOD_REQUEST = "FETCH_ABSENCESSTATSFORCOURSEYEARGROUPINPERIOD_REQUEST";
export const FETCH_ABSENCESSTATSFORCOURSEYEARGROUPINPERIOD_SUCCESS = "FETCH_ABSENCESSTATSFORCOURSEYEARGROUPINPERIOD_SUCCESS";
export const FETCH_ABSENCESSTATSFORCOURSEYEARGROUPINPERIOD_FAILURE = "FETCH_ABSENCESSTATSFORCOURSEYEARGROUPINPERIOD_FAILURE";

export const SEND_CREATEATTENDANCECERTIFICATES_REQUEST = "SEND_CREATEATTENDANCECERTIFICATES_REQUEST";
export const SEND_CREATEATTENDANCECERTIFICATES_SUCCESS = "SEND_CREATEATTENDANCECERTIFICATES_SUCCESS";
export const SEND_CREATEATTENDANCECERTIFICATES_FAILURE = "SEND_CREATEATTENDANCECERTIFICATES_FAILURE";

export const fetchAbsencesStatsForCourseYearGroupInPeriodRequest = (courseYearGroup, startingDate, endingDate) => ({
    type: FETCH_ABSENCESSTATSFORCOURSEYEARGROUPINPERIOD_REQUEST,
    courseYearGroup: courseYearGroup,
    startingDate: startingDate,
    endingDate: endingDate
});

export const fetchAbsencesStatsForCourseYearGroupInPeriodSuccess = (stats) => ({
    type: FETCH_ABSENCESSTATSFORCOURSEYEARGROUPINPERIOD_SUCCESS,
    stats: stats
});

export const fetchAbsencesStatsForCourseYearGroupInPeriodFailure = (errorMessage) => ({
    type: FETCH_ABSENCESSTATSFORCOURSEYEARGROUPINPERIOD_FAILURE,
    errorMessage: errorMessage
});

export const sendCreateAttendanceCertificatesRequest = (students, startingDate, endingDate) => ({
    type: SEND_CREATEATTENDANCECERTIFICATES_REQUEST,
    students: students,
    startingDate: startingDate,
    endingDate: endingDate
});

export const sendCreateAttendanceCertificatesSuccess = (attendanceCertificates) => ({
    type: SEND_CREATEATTENDANCECERTIFICATES_SUCCESS,
    attendanceCertificates: attendanceCertificates
});

export const sendCreateAttendanceCertificatesFailure = (errorMessage) => ({
    type: SEND_CREATEATTENDANCECERTIFICATES_FAILURE,
    errorMessage: errorMessage
});