import * as types from "../actions";

export const initialState = {
  stats: undefined,
  errorMessage: undefined,
  isLoading: false
};

export const StudentsAttendanceStatsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_STUDENTSATTENDANCESTATS_REQUEST:
            return Object.assign({}, state, {
                stats: undefined,
                errorMessage: undefined,
                isLoading: true
            });
        case types.FETCH_STUDENTSATTENDANCESTATS_SUCCESS:
            return Object.assign({}, state, {
                stats: addExtraStats(action.stats),
                errorMessage: undefined,
                isLoading: false
            });
        case types.FETCH_STUDENTSATTENDANCESTATS_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.errorMessage,
                isLoading: false
            });
        default:
            return state;
    }
}

const addExtraStats = (stats) => {
    let globalAbsenceTimeInMinutes = 0;
    const newStats = stats.map(stat => {
        let newStat = {...stat};

        const filteredAbsences = stat.absences.filter(absence => !absence.motive || (absence.motive && absence.motive.error === false));
        newStat.numberOfAbsences = filteredAbsences.length;
        newStat.unjustifiedAbsences = filteredAbsences.filter(absence => absence.motive && absence.motive.justified === false).length;

        const reducer = (previousValue, currentValue) => previousValue + currentValue;
        const totalAbsenceInMinutes = filteredAbsences.map(absence => absence.absenceDurationInMinute).reduce(reducer, 0);
        const hours = Math.trunc(totalAbsenceInMinutes / 60);
        const minutes = Math.trunc(totalAbsenceInMinutes % 60);
        newStat.totalAbsenceTime = hours + "h" + (minutes < 10 ? "0" : "") + minutes;
        newStat.totalAbsenceInMinutes = totalAbsenceInMinutes;
        globalAbsenceTimeInMinutes += newStat.totalAbsenceInMinutes;

        newStat.variance = "-";
        return newStat;
    });

    const averageAbsenceTimeInMinutes = newStats.length > 0 ? globalAbsenceTimeInMinutes / newStats.length : 0;
    newStats.forEach(newStat => {
        const absenceDiffToAverage = newStat.totalAbsenceInMinutes - averageAbsenceTimeInMinutes;
        const hours = Math.trunc(Math.abs(absenceDiffToAverage / 60));
        const minutes = Math.trunc(Math.abs(absenceDiffToAverage % 60));
        newStat.variance = (absenceDiffToAverage < 0 ? "-" : "") + hours + "h" + minutes;
    });

    return newStats;
}

/* To populate with fake data

const addExtraStats = (stats) => {
    const newStats = stats.map(stat => {
        let newStat = {...stat};
        newStat.numberOfAbsences = Math.floor(Math.random() * stat.numberOfAbsences);
        newStat.unjustifiedAbsences = Math.floor(Math.random() * newStat.numberOfAbsences);
        const hours = Math.floor((Math.random() + 0.5) * newStat.numberOfAbsences);
        const minutes = (Math.random() > 0.5 ? "30" : "00")
        newStat.totalAbsenceTime = hours + "h" + minutes;
        const percentage = Math.random();
        const value = (percentage > 0.5 ? ((percentage - 0.5) * 90) : (percentage * 90)) + "";
        newStat.variance = value.slice(0, (value.indexOf(".")) +3) + "%";
        return newStat;
    });
    return newStats;
}*/
