import React, { useState } from "react";

import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { ActionableDialog } from '../../../../shared/components';

export function CancelMotiveModal(props) {
    const [hasSentRequest, setHasSentRequest] = useState(false);

    const getContent = () => {
        return {
            title: "Annuler la suppression",
            text: getSelect()
        }
    }

    const handleSendRequest = () => {
        let absenceIds = [];
        if (props.data[0].absences) {
            absenceIds = props.data.flatMap(row => row.absences).map(absence => absence.absenceId);
        } else {
            absenceIds = props.data.map(absence => absence.id);
        }

        if (absenceIds) {
            setHasSentRequest(true);
            props.sendRequest(absenceIds);
        }
    }
    
    const getSelect = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', maxWidth: '500px'}}>
                <Typography>
                    Vous êtes sur le point de retirer la suppression des absences sélectionnées, ce qui va les passer en non traitées.
                </Typography>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    disabled={props.success || props.failure}
                    onClick={handleSendRequest}
                    loading={props.isLoading}
                >
                    Confirmer l'annulation
                </LoadingButton>
                { props.success
                    ? <Alert severity="success">L'annulation a été prise en compte, les absences sont maintenant non traitées.</Alert>
                    : null
                }
                { props.failure
                    ? <Alert severity="error">Une erreur s'est produite, veuillez contacter un administrateur à admin@alumneo.fr.</Alert>
                    : null
                }
            </div>
        );
    }

    return (
        <ActionableDialog
            open={props.open}
            onClose={() => props.onClose(hasSentRequest)}
            content={getContent()}
        />
    );
}
