import React, { useEffect, useState } from "react";
import moment from 'moment';
import 'moment/locale/fr';

import { styled } from '@mui/material/styles';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {
    ResponsiveContainer, LineChart, Line, XAxis, YAxis, 
    Tooltip, CartesianGrid, Legend, BarChart, Bar
} from 'recharts';
import MaterialTable from '@material-table/core';
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { RequiredCourseSessionsModal } from "./RequiredCourseSessionsModal";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function formatXAxis(tickItem) {
    // If using moment.js
    return moment(tickItem).format('MMM YY')
}

export default function StudentAttendanceTab(props) {
    const [studentStats, setStudentStats] = useState({ absences: []});
    const [showRequiredCourseSessionsModal, setShowRequiredCourseSessionsModal] = useState(false);

    useEffect(() => {
        if (props.selectedStudent && props.stats && props.stats.length > 0) {
            const studentAbsences = props.stats.find(student => student.studentId === props.selectedStudent).absences
                .filter(absence => absence.motive && !absence.motive.error);

            const numberOfAbsencesPerMonth = new Map();
            const numberOfAbsencesPerMotiveType = new Map();
            studentAbsences.forEach(absence => {
                const yearMonth = absence.courseSession.startTime.substring(0, 7);
                const motiveType = absence.motive.typeDescription;
                if (numberOfAbsencesPerMonth.has(yearMonth)) {
                    numberOfAbsencesPerMonth.set(yearMonth, numberOfAbsencesPerMonth.get(yearMonth) + 1);
                } else {
                    numberOfAbsencesPerMonth.set(yearMonth, 1);
                }

                if (numberOfAbsencesPerMotiveType.has(motiveType)) {
                    numberOfAbsencesPerMotiveType.set(motiveType, numberOfAbsencesPerMotiveType.get(motiveType) + 1);
                } else {
                    numberOfAbsencesPerMotiveType.set(motiveType, 1);
                }
            });

            setStudentStats({
                absences: studentAbsences.map(absence => ({ ...absence, transmitted: (absence.motive.justified ? 'oui' : 'non')})),
                numberOfAbsencesPerMonth: Array.from(numberOfAbsencesPerMonth, ([yearMonth, value]) => ({name: yearMonth, uv: value})).sort((a, b) => a.name.localeCompare(b.name)),
                numberOfAbsencesPerMotiveType: Array.from(numberOfAbsencesPerMotiveType, ([motiveType, value]) => ({name: motiveType, uv: value})).sort((a, b) => a.name.localeCompare(b.name))
            });
        }
    }, [props]);

    const handleCloseRequiredCourseSessionsModal = () => {
        setShowRequiredCourseSessionsModal(false);
    };

    const handleOpenRequiredCourseSessionsModal = () => {
        setShowRequiredCourseSessionsModal(true);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs="auto">
                    <Item>
                        <Box sx={{ width: 700, height: 330 }}>
                        <text x={700 / 2} y={20} fill="black" textAnchor="middle" dominantBaseline="central">
                            <tspan fontSize="14">Evolution des absences sur la période</tspan>
                        </text>
                            <ResponsiveContainer width="100%" height="90%">
                                <LineChart
                                    width={700}
                                    height={300}
                                    data={studentStats.numberOfAbsencesPerMonth}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" tickFormatter={formatXAxis} />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="uv" stroke="#8884d8" activeDot={{ r: 8 }} name="Nombre d'absences" />
                                </LineChart>
                            </ResponsiveContainer>
                        </Box>
                    </Item>
                </Grid>
                <Grid item xs="auto">
                    <Item>
                    <Box sx={{ width: 700, height: 330 }}>
                        
                    <text x={700 / 2} y={20} fill="black" textAnchor="middle" dominantBaseline="central">
                        <tspan fontSize="14">Absences sur la période par motif</tspan>
                    </text>
                    <BarChart width={700} height={300} data={studentStats.numberOfAbsencesPerMotiveType} barGap={6}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="uv" fill="#8884d8" name="Motif" />
                    </BarChart>
                    </Box>
                    </Item>
                </Grid>
                <Grid item xs="auto">
                    <Box sx={{ width: 1450, height: 800 }}>
                        <MaterialTable
                            columns={[
                                { title: 'Date', field: 'courseSession.startTime', type: 'date' },
                                { title: 'Cours', field: 'courseSession.discipline', type: 'text' },
                                { title: 'Motif', field: 'motive.typeDescription', type: 'text' },
                                { title: 'Justificatif transmis', field: 'transmitted', type: 'text' },
                                { title: 'Commentaire', field: 'motive.complementaryNote', type: 'text' },
                                { title: 'Durée (en min)', field: 'absenceDurationInMinute', type: 'numeric' },
                            ]}
                            data={studentStats.absences}
                            title={"Absences de l'étudiant"}
                            isLoading={props.isLoading}
                            options={{
                                search: false,
                                exportMenu: [
                                    {
                                        label: "Exporter en PDF",
                                        exportFunc: (cols, datas) => ExportPdf(cols, datas, "Absences de l'étudiant"),
                                    },
                                    {
                                        label: "Exporter en CSV",
                                        exportFunc: (cols, datas) => ExportCsv(cols, datas, "Absence de l'étudiant"),
                                    },
                                ],
                                pageSize: 10,
                                pageSizeOptions: [10, 30, 50]
                            }}
                            localization={{
                                toolbar: {
                                    nRowsSelected: '{0} ligne(s) sélectionnée(s)'
                                },
                                body: {
                                    emptyDataSourceMessage: "Aucune absence à afficher"
                                },
                                pagination: {
                                    labelRowsSelect: "lignes"
                                }
                            }}
                            actions={[
                                {
                                    tooltip: "Affiche les cours sur la période où la présence de l'étudiant est requise.",
                                    isFreeAction: true,
                                    onClick: handleOpenRequiredCourseSessionsModal
                                }
                            ]}
                            components={{
                                Action: props => (
                                  <Button
                                    onClick={(event) => props.action.onClick(event, props.data)}
                                    variant="contained"
                                    color="primary"
                                    style={{textTransform: 'none'}}
                                  >
                                    Afficher les cours sur la période
                                  </Button>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>

            
            { showRequiredCourseSessionsModal ? 
                <RequiredCourseSessionsModal
                    open={showRequiredCourseSessionsModal}
                    onClose={handleCloseRequiredCourseSessionsModal}
                    courseSessions={props.requiredCourseSessions}
                />
                : null
            }
        </Box>
    );
}







