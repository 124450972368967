import * as React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';

import '../../../shared/styles/FilterRow.css';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

class FilterRow extends React.Component {

    renderLookupFilter = (columnDef) => (
        <FormControl style={{ width: '100%' }}>
            <InputLabel htmlFor="select-multiple-checkbox">{columnDef.filterPlaceholder}</InputLabel>
            <Select
                multiple
                value={columnDef.tableData.filterValue || []}
                onChange={event => {
                    this.props.onFilterChanged(columnDef.tableData.id, event.target.value);
                }}
                input={<Input id="select-multiple-checkbox" />}
                renderValue={selecteds => selecteds.map(selected => columnDef.lookup[selected]).join(', ')}
                MenuProps={MenuProps}
            >
                {
                    Object.keys(columnDef.lookup).map(key => (
                        <MenuItem key={key} value={key}>
                            <Checkbox checked={columnDef.tableData.filterValue ? columnDef.tableData.filterValue.indexOf(key.toString()) > -1 : false} />
                            <ListItemText primary={columnDef.lookup[key]} />
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )

    renderBooleanFilter = (columnDef) => (
        <Checkbox
            indeterminate={columnDef.tableData.filterValue === undefined}
            checked={columnDef.tableData.filterValue === 'checked'}
            onChange={() => {
                let val;
                if (columnDef.tableData.filterValue === undefined) {
                    val = 'checked';
                } else if (columnDef.tableData.filterValue === 'checked') {
                    val = 'unchecked';
                }

                this.props.onFilterChanged(columnDef.tableData.id, val);
            }}
        />
    )

    renderDefaultFilter = (columnDef) => {
        return (
            <div style={{ boxSizing: "border-box", padding: "5px 10px 5px 10px" }}>
                <TextField
                    style={columnDef.type === 'numeric' ? { float: 'right' } : {}}
                    type={columnDef.type === 'numeric' ? 'number' : 'search'}
                    value={columnDef.tableData.filterValue || ''}
                    placeholder={columnDef.filterPlaceholder || ''}
                    onChange={(event) => {
                        this.props.onFilterChanged(columnDef.tableData.id, event.target.value);
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>

        );
    }

    /*renderDateTypeFilter = (columnDef) => {
        let dateInputElement = null;
        const onDateInputChange = date => this.props.onFilterChanged(columnDef.tableData.id, date);
        if (columnDef.type === 'date') {
            dateInputElement = (
                <DatePicker
                    value={columnDef.tableData.filterValue || null}
                    onChange={onDateInputChange}
                    clearable
                    views={["year", "month"]}
                    minDate={new Date("2016-01-01")}
                    maxDate={new Date("2029-12-31")}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <DateRange />
                            </InputAdornment>
                        )
                    }}
                />
            );
        } else if (columnDef.type === 'datetime') {
            dateInputElement = (
                <DateTimePicker
                    value={columnDef.tableData.filterValue || null}
                    onChange={onDateInputChange}
                    clearable
                />
            );
        } else if (columnDef.type === 'time') {
            dateInputElement = (
                <TimePicker
                    value={columnDef.tableData.filterValue || null}
                    onChange={onDateInputChange}
                    clearable
                />
            );
        }
        return (
            <div style={{ boxSizing: "border-box", padding: "5px 10px 5px 10px" }}>
                <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={frLocale}>
                    {dateInputElement}
                </MuiPickersUtilsProvider>
            </div>
        );
    }*/

    renderCourseYearGroups(columnDef) {
        console.log("special render");
        console.log(columnDef);
    }

    renderCourseYearGroupsFilter = (columnDef) => {
        return (
        <FormControl style={{ width: '100%' }}>
            <InputLabel htmlFor="select-multiple-checkbox">{columnDef.filterPlaceholder}</InputLabel>
            <Select
                value={columnDef.currentValue || ""}
                onChange={(event) => columnDef.customOnChange(event.target.value)}
                input={<Input id="select-multiple-checkbox" />}
                MenuProps={MenuProps}
            >
                {columnDef.customData.map((label, index) => {
                    return (
                        <MenuItem value={label} key={index}>{label}</MenuItem>
                    );
                })}
            </Select>
        </FormControl>);
    }

    getComponentForColumn(columnDef) {
        if (columnDef.filtering === false) {
            return null;
        }

        if (columnDef.field || columnDef.customFilterAndSearch) {
            if (columnDef.lookup) {
                return this.renderLookupFilter(columnDef);
            } else if (columnDef.type === 'boolean') {
                return this.renderBooleanFilter(columnDef);
            } else if (['date', 'datetime', 'time'].includes(columnDef.type)) {
                return this.renderDateTypeFilter(columnDef);
            } else if (columnDef.customType === 'courseYearGroups') {
                return this.renderCourseYearGroupsFilter(columnDef);
            } else {
                return this.renderDefaultFilter(columnDef);
            }
        }
    }

    render() {
        const columns = this.props.columns
            .filter(columnDef => !columnDef.hidden && !(columnDef.tableData.groupOrder > -1))
            .sort((a, b) => a.tableData.columnOrder - b.tableData.columnOrder)
            .map(columnDef => (
                <TableCell key={columnDef.tableData.id} style={{ ...this.props.filterCellStyle, ...columnDef.filterCellStyle }}>
                    {this.getComponentForColumn(columnDef)}
                </TableCell>
            ));

        if (this.props.selection) {
            columns.splice(0, 0, <TableCell padding="none" key="key-selection-column" />);
        }

        if (this.props.hasActions) {
            if (this.props.actionsColumnIndex === -1) {
                columns.push(<TableCell key="key-action-column" />);
            } else {
                let endPos = 0;
                if (this.props.selection) {
                    endPos = 1;
                }
                columns.splice(this.props.actionsColumnIndex + endPos, 0, <TableCell key="key-action-column" />);
            }
        }

        if (this.props.hasDetailPanel) {
            columns.splice(0, 0, <TableCell padding="none" key="key-detail-panel-column" />);
        }

        if (this.props.isTreeData > 0) {
            columns.splice(0, 0,
                <TableCell
                    padding="none"
                    key={"key-tree-data-filter"}
                />
            );
        }

        this.props.columns
            .filter(columnDef => columnDef.tableData.groupOrder > -1)
            .forEach(columnDef => {
                columns.splice(0, 0, <TableCell padding="checkbox" key={"key-group-filter" + columnDef.tableData.id} />);
            });

        return (
            <TableRow style={{ height: 10 }}>
                {columns}
            </TableRow>
        );
    }
}

FilterRow.defaultProps = {
    columns: [],
    selection: false,
    hasActions: false,
    localization: {
        filterTooltip: 'Filter'
    },
    hideFilterIcons: false,
};

export default FilterRow;