import moment from 'moment';

import { authenticationFailure } from "../redux/actions";
import { redirectToLoginPage } from "./routing";
import { MAIN_SERVER_URL } from "../config";

export function simpleCallService(endPoint, requestMethod, requestCallback, successCallback, failureCallback, body, expectJsonResponse) {
    return callService(
        MAIN_SERVER_URL + endPoint,
        { method: requestMethod, body: (body ? JSON.stringify(body) : undefined)},
        requestCallback,
        successCallback,
        failureCallback,
        expectJsonResponse
    );
};

export function callService(url, options, request, success, failure, expectJsonResponse = false) {
    return function (dispatch) {
        dispatch(request());

        return fetchWithTimeOut(url, options)
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return (expectJsonResponse) ? response.json() : response.text();
                } else {
                    throw response;
                }
            })
            .then(result => {
                dispatch(success(result));
            })
            .catch(error => {
                if (error.status === 403) {
                    // Token expired, should reconnect.
                    console.log(error);
                    redirectToLoginPage();
                    dispatch(authenticationFailure("Votre session a expiré, veuillez vous reconnecter."));
                } else if (error.status === 403) {
                    dispatch(failure("Vous n'êtes pas autorisé à effectuer cette opération."));
                /*} else if (error.status === 400 || error.status === 500) {
                    error.json().then(error => dispatch(failure(error.message)));*/
                } else {
                    dispatch(failure("Une erreur est survenue. Veuillez contacter un administrateur."));
                }
            });
    }
}

export function fetchWithTimeOut(url, options = {}, timeout = 10000) {
    if (!options.headers) {
        options.headers = {};
    }
    if (localStorage.token) {
        options.headers.Authorization = localStorage.token;
    }
    if (localStorage.tenant) {
        options.headers.Tenant = localStorage.tenant;
    }
    if ((options.method.toUpperCase() === "POST" || options.method.toUpperCase() === "PUT") && !options.headers["Content-Type"]) {
        options.headers["Content-Type"] = "application/json";
    }
    
    return Promise.race([
        fetch(url, options),
        new Promise((_, reject) =>
            setTimeout(() => reject(new Error('timeout')), timeout)
        )
    ]);
}

/* We need to transform date because toISOString put time in UTC */
export function getDateString(date) {
    return moment(date).add(-1 * date.getTimezoneOffset(), 'm').toDate().toISOString();
}
