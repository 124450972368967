import React, { useEffect, useState } from "react";

import LoggedInLayout from "../../../shared/layouts/logged-in/LoggedInLayout";
import RealTimeAttendancyCalendar from "./RealTimeAttendancyCalendar";
import RealTimeAttendancyTopBar from "./RealTimeAttendancyTopBar";
import { SnackBarErrorMessageGroup } from '../../../shared/components';

export default function RealTimeAttendancyView(props) {
    const [fetchedCourseYearGroups, setFetchedCourseYearGroups] = useState(false);
    const [selectedCourseYearGroup, setSelectedCourseYearGroup] = useState(null);
    const [currentDateInWeek, setCurrentDateInWeek] = useState(new Date());
    const [displayWithSchedule, setDisplayWithSchedule] = useState(false);

    /* Fetching Available course year groups */
    useEffect(() => {
        if (!fetchedCourseYearGroups) {
            setFetchedCourseYearGroups(true);
            props.sendCourseYearGroupsRequest();
        }
    }, [fetchedCourseYearGroups, props]);

    /* Refresh the attendancy when attendance records are created */
    useEffect(() => {
        if (selectedCourseYearGroup) {
            props.sendRetailTimeAttendancyRequest(selectedCourseYearGroup, currentDateInWeek, displayWithSchedule);
        }
    }, [props.attendanceRecordCreated]);

    const handleCourseYearGroupSelection = (courseYearGroup) => {
        setSelectedCourseYearGroup(courseYearGroup);
        props.sendRetailTimeAttendancyRequest(courseYearGroup, currentDateInWeek, displayWithSchedule);

        // If teacher, we don't request student to avoid allowing to add attendance records.
        if (localStorage.features !== "REAL_TIME_ATTENDANCE") {
            props.sendFetchStudentsRequest(courseYearGroup);
        }
    }

    const handleNewDateRange = (startDate, endDate) => {
        var dateInWeek = new Date(startDate);
        dateInWeek.setHours(dateInWeek.getHours() + 12); // Adding some hours to avoid timezone issue.
        setCurrentDateInWeek(dateInWeek);
        if (selectedCourseYearGroup) {
            props.sendRetailTimeAttendancyRequest(selectedCourseYearGroup, dateInWeek, displayWithSchedule);
        }
    };

    const handleDisplayWithScheduleSelection = (displayWithSchedule) => {
        setDisplayWithSchedule(displayWithSchedule);
        if (selectedCourseYearGroup) {
            props.sendRetailTimeAttendancyRequest(selectedCourseYearGroup, currentDateInWeek, displayWithSchedule);
        }
    };

    return (
        <LoggedInLayout isLoggedIn={props.isLoggedIn} username={props.username}>
            <RealTimeAttendancyTopBar
                handleCourseYearGroupSelection={handleCourseYearGroupSelection}
                courseYearGroups={props.courseYearGroups}
                students={props.students}
                sendCreateAttendanceRecordRequest={props.sendCreateAttendanceRecordRequest}
                isCreatingAttendanceRecord={props.isCreatingAttendanceRecord}
                attendanceRecordCreated={props.attendanceRecordCreated}
                attendanceRecordCreationErrorMessage={props.attendanceRecordCreationErrorMessage}
                handleDisplayWithScheduleSelection={handleDisplayWithScheduleSelection}
            />
            <RealTimeAttendancyCalendar
                defaultDate={new Date()}
                handleNewDateRange={(startDate, endDate) => handleNewDateRange(startDate, endDate)}
                events={props.realtimeAttendancy}
                isFetchingRealTimeAttendancy={props.isFetching}
            />
            <SnackBarErrorMessageGroup
                messages={[props.courseYearGroupsErrorMessage, props.realtimeAttendancyErrorMessage, props.studentsErrorMessage]}
            />
        </LoggedInLayout>
    );
}