/* Shared absence related failures */

export const ABSENCE_HANDLING_REQUEST_FAILURE = "ABSENCE_HANDLING_REQUEST_FAILURE";

export const absenceHandlingRequestFailure = (errorMessage) => ({
    type: ABSENCE_HANDLING_REQUEST_FAILURE,
    errorMessage: errorMessage
});

/* Fetch the absences for a course year group */

export const FETCH_ABSENCESOFCOURSEYEARGROUP_REQUEST = "FETCH_ABSENCESOFCOURSEYEARGROUP_REQUEST";
export const FETCH_ABSENCESOFCOURSEYEARGROUP_SUCCESS = "FETCH_ABSENCESOFCOURSEYEARGROUP_SUCCESS";

export const fetchAbsencesOfCourseYearGroupRequest = (courseYearGroup) => ({
    type: FETCH_ABSENCESOFCOURSEYEARGROUP_REQUEST,
    courseYearGroup: courseYearGroup
});

export const fetchAbsencesOfCourseYearGroupSuccess = (absences) => ({
    type: FETCH_ABSENCESOFCOURSEYEARGROUP_SUCCESS,
    absences: absences
});

/* Fetch the list of possible motive types */

export const FETCH_ABSENCEMOTIVETYPES_REQUEST = "FETCH_ABSENCEMOTIVETYPES_REQUEST";
export const FETCH_ABSENCEMOTIVETYPES_SUCCESS = "FETCH_ABSENCEMOTIVETYPES_SUCCESS";

export const fetchAbsenceMotiveTypesRequest = () => ({
    type: FETCH_ABSENCEMOTIVETYPES_REQUEST
});

export const fetchAbsenceMotiveTypesSuccess = (motiveTypes) => ({
    type: FETCH_ABSENCEMOTIVETYPES_SUCCESS,
    motiveTypes: motiveTypes
});

/* Update absences motive */

export const UPDATE_ABSENCEMOTIVE_REQUEST = "UPDATE_ABSENCEMOTIVE_REQUEST";
export const UPDATE_ABSENCEMOTIVE_SUCCESS = "UPDATE_ABSENCEMOTIVE_SUCCESS";
export const UPDATE_ABSENCEMOTIVE_FAILURE = "UPDATE_ABSENCEMOTIVE_FAILURE";

export const updateAbsenceMotiveRequest = () => ({
    type: UPDATE_ABSENCEMOTIVE_REQUEST
});

export const updateAbsenceMotiveSuccess = () => ({
    type: UPDATE_ABSENCEMOTIVE_SUCCESS
});

export const updateAbsenceMotiveFailure = () => ({
    type: UPDATE_ABSENCEMOTIVE_FAILURE
});

/* Cancel absences motive */

export const CANCEL_ABSENCEMOTIVE_REQUEST = "CANCEL_ABSENCEMOTIVE_REQUEST";
export const CANCEL_ABSENCEMOTIVE_SUCCESS = "CANCEL_ABSENCEMOTIVE_SUCCESS";
export const CANCEL_ABSENCEMOTIVE_FAILURE = "CANCEL_ABSENCEMOTIVE_FAILURE";

export const cancelAbsenceMotiveRequest = () => ({
    type: CANCEL_ABSENCEMOTIVE_REQUEST
});

export const cancelAbsenceMotiveSuccess = () => ({
    type: CANCEL_ABSENCEMOTIVE_SUCCESS
});

export const cancelAbsenceMotiveFailure = () => ({
    type: CANCEL_ABSENCEMOTIVE_FAILURE
});

/* Create an absence */

export const CREATE_ABSENCE_REQUEST = "CREATE_ABSENCE_REQUEST";
export const CREATE_ABSENCE_SUCCESS = "CREATE_ABSENCE_SUCCESS";
export const CREATE_ABSENCE_FAILURE = "CREATE_ABSENCE_FAILURE";

export const createAbsenceRequest = () => ({
    type: CREATE_ABSENCE_REQUEST
});

export const createAbsenceSuccess = () => ({
    type: CREATE_ABSENCE_SUCCESS
});

export const createAbsenceFailure = () => ({
    type: CREATE_ABSENCE_FAILURE
});