import * as actions from "../actions";
import * as endpoints from "../api";
import { simpleCallService, getDateString } from "../../utils/networking";

/** Send request to get course sessions for a given period  */
export function fetchCourseSessions(courseYearGroup, startDate, endDate) {
    return simpleCallService(
        endpoints.COURSE_SESSION_ENDPOINT
        + "?courseYearGroup=" + courseYearGroup
        + "&startingDate=" + getDateString(startDate)
        + "&endingDate=" + getDateString(endDate),
        "GET",
        actions.fetchCourseSessionsRequest,
        actions.fetchCourseSessionsSuccess,
        actions.fetchCourseSessionsFailure,
        null,
        true
    );
}

/** Send request to save course sessions */
export function sendCourseSessions(courseYearGroup, courseSessionsToSave) {
    
    /* We need to transform date because JSON.stringify put date in UTC time */
    const courseSessions = courseSessionsToSave
        .filter(cs => !cs.confirmed) // We remove the already confirmed sessions.
        .map(event => {
            return {
                discipline: event.title,
                dateRange: {
                    startTime: getDateString(event.start),
                    endTime: getDateString(event.end)
                },
                studentGroups: event.studentGroups.map(group => { return { name: group } }),
                optional: event.isOptional || false // false if not present
            }
        });

    return simpleCallService(
        endpoints.COURSE_SESSION_ENDPOINT + "?courseYearGroup=" + courseYearGroup,
        "POST",
        actions.sendCourseSessionsRequest,
        actions.sendCourseSessionsSuccess,
        actions.sendCourseSessionsFailure,
        courseSessions,
        true
    );
}

/** Send request to get required course sessions of a student for a given period  */
export function fetchStudentCourseSessions(studentId, startDate, endDate) {
    return simpleCallService(
        endpoints.COURSE_SESSIONS_OF_STUDENT_ENDPOINT
        + "?studentId=" + studentId
        + "&startingDate=" + getDateString(startDate)
        + "&endingDate=" + getDateString(endDate),
        "GET",
        actions.fetchStudentCourseSessionsRequest,
        actions.fetchStudentCourseSessionsSuccess,
        actions.fetchStudentCourseSessionsFailure,
        null,
        true
    );
}