export const AUTHENTICATION_REQUEST = "AUTHENTICATION_REQUEST";
export const AUTHENTICATION_SUCCESS = "AUTHENTICATION_SUCCESS";

export const PASSWORDRESET_REQUEST = "PASSWORDRESET_REQUEST";
export const PASSWORDRESET_SUCCESS = "PASSWORDRESET_SUCCESS";
export const PASSWORDRESET_FAILURE = "PASSWORDRESET_FAILURE";

export const NEWPASSWORD_REQUEST = "NEWPASSWORD_REQUEST";
export const NEWPASSWORD_SUCCESS = "NEWPASSWORD_SUCCESS";
export const NEWPASSWORD_FAILURE = "NEWPASSWORD_FAILURE";

export const authenticationRequest = (username, password) => ({
    type: AUTHENTICATION_REQUEST,
    username: username,
    password: password
});

export const authenticationSuccess = (username) => ({
    type: AUTHENTICATION_SUCCESS,
    username: username
});

/* Password Reset Request */

export const passwordResetRequest = (emailAddress) => ({
    type: PASSWORDRESET_REQUEST,
    emailAddress: emailAddress
});

export const passwordResetSuccess = () => ({
    type: PASSWORDRESET_SUCCESS
});

export const passwordResetFailure = (errorMessage) => ({
    type: PASSWORDRESET_FAILURE,
    errorMessage: errorMessage
});

/* Password Reset */

export const newPasswordRequest = () => ({
    type: NEWPASSWORD_REQUEST
});

export const newPasswordSuccess = () => ({
    type: NEWPASSWORD_SUCCESS
});

export const newPasswordFailure = (errorMessage) => ({
    type: NEWPASSWORD_FAILURE,
    errorMessage: errorMessage
});
