export const FETCH_STUDENTSATTENDANCESTATS_REQUEST = "FETCH_STUDENTSATTENDANCESTATS_REQUEST";
export const FETCH_STUDENTSATTENDANCESTATS_SUCCESS = "FETCH_STUDENTSATTENDANCESTATS_SUCCESS";
export const FETCH_STUDENTSATTENDANCESTATS_FAILURE = "FETCH_STUDENTSATTENDANCESTATS_FAILURE";

export const fetchStudentsAttendanceStatsRequest = () => ({
    type: FETCH_STUDENTSATTENDANCESTATS_REQUEST
});

export const fetchStudentsAttendanceStatsSuccess = (stats) => ({
    type: FETCH_STUDENTSATTENDANCESTATS_SUCCESS,
    stats: stats
});

export const fetchStudentsAttendanceStatsFailure = (errorMessage) => ({
    type: FETCH_STUDENTSATTENDANCESTATS_FAILURE,
    errorMessage: errorMessage
});