import React from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import RouterIcon from '@material-ui/icons/Router';
import SchoolIcon from '@material-ui/icons/School';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import DescriptionIcon from '@material-ui/icons/Description';
import InfoIcon from '@material-ui/icons/Info';

import PaperSheet from "../../../shared/components/PaperSheet";
import LoggedInLayout from "../../../shared/layouts/logged-in/LoggedInLayout";
import CardReaderRow from "./CardReaderRow";

const tableCellStyle = {
	display: 'flex',
	alignItems: 'center'
};

export default class CardReaderTable extends React.Component {

	componentDidMount() {
		this.props.sendCardReadersRequest();
	}

	render() {
		return (
			<LoggedInLayout isLoggedIn={this.props.isLoggedIn} username={this.props.username}>
				<PaperSheet>

					<Table>
						<TableHead>
							<TableRow>
								<TableCell> <div style={tableCellStyle}><RouterIcon /> Badgeuse</div> </TableCell>
								<TableCell> <div style={tableCellStyle}><SchoolIcon /> Ecole</div> </TableCell>
								<TableCell> <div style={tableCellStyle}><PowerSettingsNewIcon /> Création Badgeuse</div> </TableCell>
								<TableCell> <div style={tableCellStyle}><FavoriteIcon /> Dernier Heartbeat</div> </TableCell>
								<TableCell> <div style={tableCellStyle}><DescriptionIcon /> Logs</div> </TableCell>
								<TableCell> <div style={tableCellStyle}><CloudDownloadIcon /> Mise à jours</div> </TableCell>
								<TableCell> <div style={tableCellStyle}><NetworkCheckIcon /> Heartbeats</div> </TableCell>
								<TableCell> <div style={tableCellStyle}><InfoIcon /> Détails</div> </TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{this.props.cardReaders.map((item) => (
								<CardReaderRow key={item.cardReaderName} cardReader={item} dateRequest={this.props.dateRequest} />
							))}
						</TableBody>
					</Table>
				</PaperSheet>
			</LoggedInLayout>
		);
	}
}
