import React from "react";

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton  from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export function SimpleDialog(props) {
    const { onClose, open, content } = props;

    return (
        <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open} fullWidth={true} maxWidth={"lg"}>
            <DialogTitle id="simple-dialog-title">{content && content.title}
                <IconButton aria-label="close" onClick={onClose} style={{float:"right", padding:"0px"}}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {content && content.text}
            </DialogContent>
        </Dialog>
    );
}