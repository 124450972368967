import React, { useState } from "react";

import { makeStyles } from '@mui/styles';
import AddIcon from '@material-ui/icons/Add';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';

import { HelpButton, CourseYearGroupPicker } from '../../../shared/components';
import AttendanceCreationModal from './AttendanceCreationModal';

const useStyles = makeStyles({
    root: {
        marginBottom: 15,
        textAlign: "left",
        paddingLeft: 10,
        display: "inline-flex",
        minWidth: "100%",
        alignItems: "center"
    },
    rightAlign: {
        marginLeft: "auto",
        paddingRight: 10
    }
});

export default function RealTimeAttendancyTopBar(props) {
    const classes = useStyles();
    const [courseYearGroupIsSelected, setCourseYearGroupIsSelected] = useState(false);
    const [isDisplayWithScheduleChecked, setIsDisplayWithScheduleChecked] = useState(false);
    const [showCreateRecordModal, setShowCreateRecordModal] = useState(false);

    const handleCourseYearGroupSelection = (value) => {
        setCourseYearGroupIsSelected(true);
        props.handleCourseYearGroupSelection(value);
    };

    const handleDisplayWithScheduleSelection = (event) => {
        setIsDisplayWithScheduleChecked(event.target.checked);
        props.handleDisplayWithScheduleSelection(event.target.checked);
    }

    const handleAttendanceRecordCreation = (students, dateTime) => {
        props.sendCreateAttendanceRecordRequest(students, dateTime);
    }

    const handleCloseCreateRecordModal = () => {
        setShowCreateRecordModal(false);
    };

    const handleOpenCreateRecordModal = () => {
        setShowCreateRecordModal(true);
    };

    const helperText = `Cette page permet de suivre l'assiduité en temps réel.
    En sélectionnant une promotion, vous pouvez voir le nombre d'étudiants présents par demi-journée.
    En cliquant sur une demi-journée, vous accédez au détail des badgeages pour celle-ci.`;

    return (
        <Paper className={classes.root}>
            <HelpButton 
                text={helperText} 
            />
            <CourseYearGroupPicker
                courseYearGroups={props.courseYearGroups}
                handleCourseYearGroupSelection={handleCourseYearGroupSelection}
            />
            <FormGroup>
                <FormControlLabel
                    control={<Switch
                        checked={isDisplayWithScheduleChecked}
                        onChange={handleDisplayWithScheduleSelection}
                    />}
                    label="Affichage par cours"
                />
            </FormGroup>
            <div className={classes.rightAlign}>
                <Tooltip title={!courseYearGroupIsSelected ? "Sélectionnez la promotion pour laquelle vous souhaitez créer un badgeage." : ""}>
                    <span>
                        <Button variant="contained" color="primary" startIcon={<AddIcon />} 
                            onClick={handleOpenCreateRecordModal} 
                            disabled={!courseYearGroupIsSelected}
                        >
                            Créer un badgeage
                        </Button>
                    </span>
                </Tooltip>
            </div>
            {showCreateRecordModal ? 
                    <AttendanceCreationModal
                        onClose={handleCloseCreateRecordModal}
                        open={showCreateRecordModal}
                        students={props.students}
                        sendCreateAttendanceRecordRequest={handleAttendanceRecordCreation}
                        isCreatingAttendanceRecord={props.isCreatingAttendanceRecord}
                        attendanceRecordCreated={props.attendanceRecordCreated}
                        attendanceRecordCreationErrorMessage={props.attendanceRecordCreationErrorMessage}
                    />
            : null}
        </Paper>
    );
}