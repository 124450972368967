import React, { useEffect, useState } from "react";
import moment from 'moment';
import 'moment/locale/fr';
import MaterialTable from '@material-table/core';

import LoggedInLayout from "../../../shared/layouts/logged-in/LoggedInLayout";

export default function StudentAccessPageView(props) {
    const [fetchedAttendanceRecords, setFetchedAttendanceRecords] = useState(false);

    /* Fetching Attendance Records */
    useEffect(() => {
        if (!fetchedAttendanceRecords) {
            setFetchedAttendanceRecords(true);
            props.sendGetAttendanceRecordsRequest();
        }
    }, [fetchedAttendanceRecords, props]);

    return (
        <LoggedInLayout isLoggedIn={props.isLoggedIn} username={props.username}>
            <MaterialTable
                title={"Vos badgeages"}
                isLoading={props.isFetchingAttendanceRecords}
                options={{
                    pageSize: 10,
                    pageSizeOptions: [10, 50, 100]
                }}
                columns= {[ 
                    { title: 'Date et heure du badgeage', field: 'dateTime', type: 'datetime', defaultSort: "desc" }
                ]}
                data={props.attendanceRecords}
                localization={{
                    body: {
                        emptyDataSourceMessage: (Array.isArray(props.attendanceRecords) ? "Aucun badgeage à afficher" : "")
                    },
                    pagination: {
                        labelRowsSelect: "lignes"
                    }
                }}
            />
        </LoggedInLayout>
    );
}