import { connect } from "react-redux";

import DataManagementView from "./DataManagementView";
import { fetchData, sendData } from "./redux/DataManagementServices";

const mapStateToProps = state => {
    return {
        isLoggedIn: state.LoginReducer.isLoggedIn,
        username: state.LoginReducer.username,
        isLoadingSending: state.DataManagementReducer.isLoading,
        resultSending: state.DataManagementReducer.result,
        errorMessageSending: state.DataManagementReducer.errorMessage,
        isLoadingFetching: state.DataVisualisationReducer.isLoading,
        resultFetching: state.DataVisualisationReducer.result,
        requestTypeFetching : state.DataVisualisationReducer.requestType,
        errorMessageFetching: state.DataVisualisationReducer.errorMessage
    };
};

function mapDispatchToProps(dispatch) {
    return {
        sendDataRequest: (request, body) => {
            dispatch(sendData(request, body));
        },
        fetchDataRequest: (request) => {
            dispatch(fetchData(request));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DataManagementView);
