import React from "react";

import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { ActionableDialog } from '../../../shared/components';

/*
courseSessions={courseSessions}*/

export function RequiredCourseSessionsModal(props) {

    const getContent = () => {
        return {
            title: "Cours de l'étudiant sur la période",
            text: getText()
        }
    };

    const getText = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', maxWidth: '500px' }}>
                <Typography>
                    La durée totale des cours pour lesquels la présence de l'étudiant était requise sur la période est de {totalDuration(props.courseSessions)} minutes.
                    Voici la liste de ces cours sur la période :
                </Typography>
                {getCourseSessionsTable()}
            </div>
        );
    };

    const getCourseSessionsTable = () => {
        const events = props.courseSessions.sort((a, b) => a.start > b.start);
        return (
            <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Cours</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Durée en minutes</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {events.map((event, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">{event.title}</TableCell>
                            <TableCell>{formatDate(event.start)}</TableCell>
                            <TableCell>{Math.floor(Math.abs((event.end - event.start) / 1000/60))}</TableCell>
                        </TableRow>
                ))}
            </TableBody>
        </Table>
        );
    }

    const formatDate = (date) => {
        return date.getFullYear() + "-" +
            (date.getMonth() >= 9 ? "" : "0") +
            (date.getMonth() + 1) + "-" +
            (date.getDate() > 9 ? "" : "0") + date.getDate();
    };

    const totalDuration = (courseSessions) => {
        if (!courseSessions) return 0;
        return courseSessions.reduce((previousValue, event) => previousValue + Math.floor(Math.abs((event.end - event.start) / 1000/60)), 0);
    }

    return (
        <ActionableDialog
            open={props.open}
            onClose={props.onClose}
            content={getContent()}
        />
    );
}
