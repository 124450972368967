import { connect } from "react-redux";

import TopBar from "./TopBar";
import { logOut } from "../../redux/actions";
import { redirectToLoginPage } from "../../utils/routing";

const mapStateToProps = state => {
    return {
        username: state.LoginReducer.username
    };
};

function mapDispatchToProps(dispatch) {
    return {
        logOut: () => {
            dispatch(logOut());
            redirectToLoginPage();
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);