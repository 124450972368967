import React, { useEffect, useState } from "react";

import StudentAttendanceTab from "./StudentAttendanceTab";
import LoggedInLayout from "../../../shared/layouts/logged-in/LoggedInLayout";
import StudentsAttendanceMonitoringTopBar from "./StudentsAttendanceMonitoringTopBar";
import GroupAttendanceTab from "./GroupAttendanceTab";

export default function StudentsAttendanceMonitoringView(props) {
    const [isGroupViewSelected, setIsGroupViewSelected] = useState(true); // Define which tabs to display
    const [selectedStudent, setSelectedStudent] = useState('');
    const { sendCourseYearGroupsRequest, sendCourseYearGroupStatsRequest } = props;

    /* Fetching Available course year groups */
    useEffect(() => {
        sendCourseYearGroupsRequest();
    }, [sendCourseYearGroupsRequest]);

    const handleChangeView = () => {
        setIsGroupViewSelected(!isGroupViewSelected);
    }

    const handleStudentSelection = (student) => {
        setSelectedStudent(student);
    }

    const getStudentList = () => {
        return props.stats.map(stat => {
            return { studentFirstName: stat.studentFirstName, studentLastName: stat.studentLastName, studentId: stat.studentId}});
    }

    return (
        <LoggedInLayout isLoggedIn={props.isLoggedIn} username={props.username}>
            <StudentsAttendanceMonitoringTopBar
                courseYearGroups={props.courseYearGroups}
                sendCourseYearGroupStatsRequest={sendCourseYearGroupStatsRequest}
                students={getStudentList()}
                handleStudentSelection={handleStudentSelection}
                isGroupViewSelected={isGroupViewSelected}
                handleChangeView={handleChangeView}
                selectedStudent={selectedStudent}
                sendStudentCourseSessionsRequest={props.sendStudentCourseSessionsRequest}
            />
            { isGroupViewSelected ? 
                <GroupAttendanceTab
                    isLoading={props.isLoading}
                    stats={props.stats}
                />
                :
                <StudentAttendanceTab
                    isLoading={props.isLoading}
                    stats={props.stats}
                    selectedStudent={selectedStudent}
                    requiredCourseSessions={props.courseSessions}
                />
            }
        </LoggedInLayout>
    );
}
