import { connect } from "react-redux";

import AttendanceCertificatesView from './AttendanceCertificatesView';
import { fetchAbsencesStatsForCourseYearGroupInPeriod } from "./redux/AttendanceCertificatesServices";
import { fetchCourseYearGroups } from "../../../shared/redux/services";

const mapStateToProps = state => {
    return {
        isLoggedIn: state.LoginReducer.isLoggedIn,
        username: state.LoginReducer.username,
        courseYearGroups: state.SharedReducer.courseYearGroups,
        isLoading: state.AttendanceCertificatesReducer.isLoading
    };
};

function mapDispatchToProps(dispatch) {
    return {
        sendCourseYearGroupsRequest: () => {
            dispatch(fetchCourseYearGroups());
        },
        sendStudentStatsRequest: (courseYearGroup, startingDate, endingDate) => {
            dispatch(fetchAbsencesStatsForCourseYearGroupInPeriod(courseYearGroup, startingDate, endingDate));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceCertificatesView);
