import React from "react";
import { XYPlot, XAxis, YAxis, HorizontalGridLines, LineMarkSeries, LabelSeries, VerticalGridLines, Hint } from 'react-vis';

import 'react-vis/dist/style.css';

export default class CardReaderHeartBeats extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			value: null
		};
	}

	forgetValue = () => {
		this.setState({
			value: null
		});
	};

	rememberValue = value => {
		this.setState({ value });
	};


	render() {
		const optionsHeartBeat = { year: '2-digit', month: '2-digit', day: '2-digit' };

		const { value } = this.state;

		const dataHeartBeats = {};
		this.props.heartbeats.map(heartbeat => {
			const day = new Date(Date.parse(heartbeat))
			day.setHours(0, 0, 0, 0);
			return day;
		}).forEach((x) => { dataHeartBeats[x] = (dataHeartBeats[x] || 0) + 1 });


		const maxY = Math.max(...Object.values(dataHeartBeats), 280) + 20;

		const dataPlot = Object.keys(dataHeartBeats).sort((key1, key2) => new Date(key1) - new Date(key2)).map((key) => {
			return ({
				x: new Date(key).toLocaleDateString('fr-FR', optionsHeartBeat),
				y: dataHeartBeats[key],
				label: dataHeartBeats[key].toString()
			});
		});

		return (
			<XYPlot
				xType="ordinal"
				width={400}
				height={300}
				yDomain={[0, maxY]}
			>
				<HorizontalGridLines />
				<VerticalGridLines />

				<LineMarkSeries
					onValueMouseOver={this.rememberValue}
					onValueMouseOut={this.forgetValue}
					data={dataPlot}
				/>
				{value ? <Hint value={value} ><div className="rv-hint__content">{`${value.y}`}</div> </Hint> : null}
				<LabelSeries
					data={dataPlot}
				/>
				<XAxis title="Journée de la semaine" />
				<YAxis title="Nombre de heartbeats" />
			</XYPlot>
		);
	}
}
