import React from "react";
import MaterialTable from 'material-table';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FindInPageIcon from '@material-ui/icons/FindInPage';

import LoggedInLayout from "../../../shared/layouts/logged-in/LoggedInLayout";
import FilterRow from "../../business-pages/students-attendance-monitoring-page/FilterRow";
import AttendanceCertificatesCreationContainer from "./creationDialog/AttendanceCertificatesCreationContainer";
import AttendanceCertificatesTopBar from "./AttendanceCertificatesTopBar";

import '../../../shared/styles/AbsenceView.css';

export default class AttendanceCertificatesView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            creationDialogIsOpen: false
        };

        this.openCreationDialog = this.openCreationDialog.bind(this);
        this.closeCreationDialog = this.closeCreationDialog.bind(this);
        this.refreshCertificates = this.refreshCertificates.bind(this);
        this.deleteCertificates = this.deleteCertificates.bind(this);
    }

    componentDidMount() {
        this.props.sendCourseYearGroupsRequest();
    }

    handleCourseYearGroupSelection(courseYearGroup) {
        if (courseYearGroup !== this.state.selectedCourseYearGroup) {
            this.setState({
                selectedCourseYearGroup: courseYearGroup
            });
        }
    }

    openCreationDialog() {
        this.setState({
            creationDialogIsOpen: true
        });
    }

    closeCreationDialog() {
        this.setState({
            creationDialogIsOpen: false
        });
    }

    refreshCertificates() {
        console.log("refreshing");
    }

    deleteCertificates() {
        console.log("deleting certificates");
    }

    loadCertificates(courseYearGroupName) {
        console.log(courseYearGroupName);
    }

    render() {
        return (
            <LoggedInLayout isLoggedIn={this.props.isLoggedIn} username={this.props.username}>
                <AttendanceCertificatesTopBar
                    handleCourseYearGroupSelection={this.handleCourseYearGroupSelection}
                    courseYearGroups={this.props.courseYearGroups}
                    courseYearGroupsErrorMessage={this.props.courseYearGroupsErrorMessage}
                />
                <div className="certificateView">
                    <MaterialTable
                        title="Gestion des attestations de présence"
                        isLoading={this.props.isLoading}
                        columns={[
                            { title: 'Promotion', field: 'courseYearGroup', customType: 'courseYearGroups', customData: this.props.courseYearGroups || [], customOnChange: this.loadCertificates },
                            { title: 'Nom', field: 'lastName' },
                            { title: 'Prénom', field: 'firstName' },
                            { title: 'Période concernée', field: 'date', type: 'date' },
                            { title: 'Nombre d\'absences', field: 'numberOfAbsences', filtering: false },
                            { title: 'Date de création', field: 'creationDate', type: 'date' },
                            { title: "Document", field: "document", filtering: false, render: rowData => <IconButton aria-label="document" href={rowData.url}><FindInPageIcon /></IconButton> }
                        ]}
                        data={[
                            { courseYearGroup: 'ESAIP Test - SPR 2020', lastName: 'Test', firstName: "Test", date: new Date(), numberOfAbsences: 6, creationDate: new Date(), url: "/test1" },
                            { courseYearGroup: 'ESAIP Test - SPR 2021', lastName: 'Test', firstName: "Test", date: new Date(), numberOfAbsences: 6, creationDate: new Date(), url: "/" },
                        ]}
                        options={{
                            selection: true,
                            filtering: true,
                            sorting: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 50],
                            search: false
                        }}
                        actions={[
                            {
                                tooltip: 'Actualiser les attestations sélectionées',
                                textButton: 'Actualiser Attestations',
                                icon: 'edit',
                                color: 'secondary',
                                onClick: (evt, data) => this.refreshCertificates(data)
                            },
                            {
                                tooltip: 'Supprimer les attestations sélectionnées',
                                textButton: 'Supprimer Attestations',
                                icon: 'delete',
                                color: 'default',
                                onClick: (evt, data) => this.deleteCertificates(data)
                            },
                            {
                                tooltip: 'Générer de nouvelles attestations',
                                textButton: 'Générer Attestations',
                                icon: 'add',
                                color: 'primary',
                                isFreeAction: true,
                                onClick: (evt) => this.openCreationDialog()
                            }
                        ]}
                        components={{
                            FilterRow: props => {
                                return (<FilterRow {...props} />);
                            },
                            Action: props => {
                                return (
                                    <Button
                                        style={{ margin: "10px" }}
                                        variant="contained"
                                        aria-label="like"
                                        onClick={(event) => props.action.onClick(event, props.data)}
                                        color={props.action.color}
                                    >
                                        <Icon>{props.action.icon}</Icon>
                                        {props.action.textButton}
                                    </Button>
                                );
                            }
                        }}
                        localization={{
                            toolbar: {
                                nRowsSelected: '{0} ligne(s) sélectionnée(s)',
                                searchPlaceholder: 'Rechercher',
                                searchTooltip: 'Faire une recherche'
                            },
                            body: { emptyDataSourceMessage: "Aucune attestation trouvée" }
                        }}
                    />
                </div>
                <AttendanceCertificatesCreationContainer
                    onClose={this.closeCreationDialog}
                    open={this.state.creationDialogIsOpen}
                    courseYearGroups={this.props.courseYearGroups || []}
                />
            </LoggedInLayout>
        );
    }
}