import React from "react";
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import { ResponsiveDialog } from "../../../shared/components";
import CardReaderUpdate from "./CardReaderUpdate";
import CardReaderHeartBeats from "./CardReaderHeartBeats";

const tableCellStyleRed = {
	backgroundColor: "#ef9a9a"
};

const tableCellStyleBlue = {
	backgroundColor: "#90caf9"
};

export default class CardReaderRow extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			open: false,
			content: { title: undefined, content: undefined },
			maxWidth: "lg",
			fullWidth: true
		};
	}

	handleModalClose = () => {
		this.setState({
			open: false
		});
	};

	handleModalOpen = (content, maxWidth, fullWidth) => {
		this.setState({
			open: true,
			content: content,
			maxWidth: maxWidth,
			fullWidth: fullWidth
		});
	};

	getLogsComponent = (lastWeekLogs, cardReaderName) => {
		const text = lastWeekLogs.map(log => log.logs).join('\n');
		const div = <div style={{ whiteSpace: "pre-wrap" }}>{text}</div>
		return {
			text: div,
			title: "Logs ".concat(cardReaderName)
		}
	}

	getUpdateComponent = (cardReader) => {
		const component = <CardReaderUpdate updateAttending={cardReader.updateAttending} updateDone={cardReader.updateDone} />
		return {
			text: component,
			title: "Mise à jours ".concat(cardReader.cardReaderName)
		}
	}

	getHearbeatsComponent = (cardReader) => {
		const component = <CardReaderHeartBeats heartbeats={cardReader.lastWeekHeartBeats} />
		return {
			text: component,
			title: "Heartbeats ".concat(cardReader.cardReaderName)
		}
	}

	render() {
		const cardReaderCreatedDate = new Date(Date.parse(this.props.cardReader.cardReaderCreatedDate));
		const optionsCreatedDate = { weekday: 'short', year: 'numeric', month: '2-digit', day: '2-digit' };

		const cardReaderLastHeartBeat = new Date(Date.parse(this.props.cardReader.cardReaderLastHeartBeatTime));
		const optionsLastHeartBeat = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };

		const diffMin = (this.props.dateRequest - cardReaderLastHeartBeat) / 60000;

		const styleRow = (diffMin < 10) ? tableCellStyleBlue : tableCellStyleRed;

		const logContent = this.getLogsComponent(this.props.cardReader.lastWeekLogs, this.props.cardReader.cardReaderName);

		return (
			<TableRow key={this.props.cardReader.cardReaderName} style={styleRow}>
				<TableCell><span style={{ fontWeight: 'bold' }}>{this.props.cardReader.cardReaderName}</span></TableCell>
				<TableCell>{this.props.cardReader.schoolName}</TableCell>
				<TableCell>{cardReaderCreatedDate.toLocaleDateString('fr-FR', optionsCreatedDate)}</TableCell>
				<TableCell><span style={(diffMin > 10) ? { fontWeight: 'bold' } : {}}>{cardReaderLastHeartBeat.toLocaleDateString('fr-FR', optionsLastHeartBeat)}</span></TableCell>

				<TableCell>
					<Link
						onClick={() => this.handleModalOpen(logContent, "md", true)}
						style={{ cursor: 'pointer' }}>
						Logs {this.props.cardReader.cardReaderName}.log
          </Link>
				</TableCell>

				<TableCell>
					<Button
						variant="contained"
						onClick={() => this.handleModalOpen(this.getUpdateComponent(this.props.cardReader), "md", false)}
						style={{ backgroundColor: "#2196f3", color: "white" }}
						size="small" >
						Mise à jours
          </Button>
				</TableCell>
				<TableCell>
					<Button
						variant="contained"
						onClick={() => this.handleModalOpen(this.getHearbeatsComponent(this.props.cardReader), "sm", false)}
						style={{ backgroundColor: "#e91e63", color: "white" }}
						size="small" >
						Heartbeats
          </Button>
				</TableCell>
				<TableCell>
					<Button variant="contained" style={{ backgroundColor: "#ffc107", color: "white" }} size="small" >
						Détails
          </Button>
				</TableCell>

				<ResponsiveDialog open={this.state.open} onClose={this.handleModalClose} content={this.state.content} maxWidth={this.state.maxWidth} fullWidth={this.state.fullWidth} />
			</TableRow>
		);
	}
}
