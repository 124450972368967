import React from "react";

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';

import { SimpleDialog } from '../../../shared/components';

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export default function ResetPasswordRequestDialog(props) {
    const [emailAddress, setEmailAddress] = React.useState('');
    const [invalidEntry, setInvalidEntry] = React.useState('');

    const handleChange = (event) => {
        setEmailAddress(event.target.value);
        setInvalidEntry("");
    }

    const handleSendRequest = () => {
        if (validateEmail(emailAddress)) {
            props.sendPasswordResetRequest(emailAddress);
        } else {
            setInvalidEntry("Le format de l'adresse est incorrect.");
        }
    }

    const getModalContent = () => {
        const content = (
            <div style={{ margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
                <Typography>Veuillez indiquer l'adresse email utilisée pour votre compte (généralement, l'adresse fournit par votre école).
                    <br />Nous vous enverrons un email contenant un lien vous permettant de réinitialiser votre mot de passe.</Typography>
                <TextField
                    id="email-address"
                    label="Adresse email du compte"
                    variant="outlined"
                    required
                    value={emailAddress}
                    onChange={handleChange}
                    error={!!invalidEntry}
                    helperText={invalidEntry}
                />
                <LoadingButton
                    variant="contained"
                    color="primary"
                    disabled={!emailAddress || props.passwordResetRequested}
                    onClick={handleSendRequest}
                    loading={props.isSendingRequest}
                >
                    Envoyer la demande de réinitialisation
                </LoadingButton>
                {props.passwordResetRequested
                    ? <Alert severity="success">La demande de réinitialisation vous a été envoyée à l'adresse indiquée.</Alert>
                    : null
                }
                {props.passwordResetErrorMessage
                    ? <Alert severity="error">L'adresse indiquée est introuvable. Si l'erreur persiste, veuillez contacter un administrateur à admin@alumneo.fr .</Alert>
                    : null
                }
            </div>
        );
        return {
            title: "Réinitialisation de mot de passe",
            text: content
        }
    }

    return (
        <SimpleDialog
            open={props.open}
            onClose={props.onClose}
            content={getModalContent()}
        />
    );
}