import * as types from "../actions";

export const initialState = {
    courseYearGroups: [],
    cygErrorMessage: undefined,
    students: [],
    studentsErrorMessage: undefined
};

export const SharedReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_COURSEYEARGROUPS_REQUEST:
            return Object.assign({}, state, {
                courseYearGroups: [],
                cygErrorMessage: undefined
            });
        case types.FETCH_COURSEYEARGROUPS_SUCCESS:
            return Object.assign({}, state, {
                courseYearGroups: action.courseYearGroups,
                cygErrorMessage: undefined
            });
        case types.FETCH_COURSEYEARGROUPS_FAILURE:
            return Object.assign({}, state, {
                cygErrorMessage: action.errorMessage
            });
        case types.FETCH_STUDENTS_REQUEST:
            return Object.assign({}, state, {
                students: [],
                studentsErrorMessage: undefined
            });
        case types.FETCH_STUDENTS_SUCCESS:
            return Object.assign({}, state, {
                students: action.students,
                studentsErrorMessage: undefined
            });
        case types.FETCH_STUDENTS_FAILURE:
            return Object.assign({}, state, {
                studentsErrorMessage: action.errorMessage
            });
        case types.SAVE_SELECTED_COURSE_YEAR_GROUP:
            return Object.assign({}, state, {
                selectedCourseYearGroup: action.selectedCourseYearGroup
            });
        default:
            return state;
    }
}