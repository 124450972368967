import { authenticationFailure } from "../../../../shared/redux/actions";
import { fetchWithTimeOut } from "../../../../shared/utils/networking";
import { MAIN_SERVER_URL } from "../../../../shared/config/index.js";
import { fetchCardReadersRequest, fetchCardReadersSuccess, fetchCardReadersFailure } from "./CardReadersActions";

export function fetchCardReaders() {
    return function (dispatch) {
        dispatch(fetchCardReadersRequest());
        const url = MAIN_SERVER_URL + "card-reader/details";
        const token = localStorage.token;
        return fetchWithTimeOut(url, { method: "GET", headers: { "Authorization": token } })
            .then(response => {
                if (response.status === 202) {
                    return response.json();
                } else {
                    throw response;
                }
            })
            .then(json => {
                dispatch(fetchCardReadersSuccess(json, Date.now()));
            })
            .catch(error => {
                if (error.status === 403) {
                    localStorage.clear();
                    dispatch(authenticationFailure("Votre session a expiré, veuillez vous reconnecter."));
                } else {
                    dispatch(fetchCardReadersFailure("Requete de récupération des badgeuses a échoué"));
                }
            });
    }
}