export const FETCH_ACTUATORS_REQUEST = "FETCH_ACTUATORS_REQUEST";
export const FETCH_ACTUATORS_SUCCESS = "FETCH_ACTUATORS_SUCCESS";
export const FETCH_ACTUATORS_FAILURE = "FETCH_ACTUATORS_FAILURE";

export const fetchActuatorsRequest = () => ({
    type: FETCH_ACTUATORS_REQUEST
});

export const fetchActuatorsSuccess = (result) => ({
    type: FETCH_ACTUATORS_SUCCESS,
    result: result
});

export const fetchActuatorsFailure = (errorMessage) => ({
    type: FETCH_ACTUATORS_FAILURE,
    errorMessage: errorMessage
});