import { connect } from "react-redux";

import ServerManagementView from "./ServerManagementView";
import { fetchActuators } from "./redux/ServerManagementServices";

const mapStateToProps = state => {
    return {
        isLoggedIn: state.LoginReducer.isLoggedIn,
        username: state.LoginReducer.username,
        isLoading: state.ServerManagementReducer.isLoading,
        result: state.ServerManagementReducer.result,
        errorMessage: state.ServerManagementReducer.errorMessage
    };
};

function mapDispatchToProps(dispatch) {
    return {
        sendActuatorsRequest: (endpoint) => {
            dispatch(fetchActuators(endpoint));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServerManagementView);