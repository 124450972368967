import React from "react";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import LaunchIcon from '@material-ui/icons/Launch';
import WifiIcon from '@material-ui/icons/Wifi';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import CodeIcon from '@material-ui/icons/Code';
import SettingsIcon from '@material-ui/icons/Settings';

import Link from '@material-ui/core/Link';

import Divider from '@material-ui/core/Divider';

export default class CardReaderUpdate extends React.Component {

  getIcon(fileType){
    switch (fileType) {
      case "JAR_FILE":
        return <CodeIcon />
      case "LAUNCHER_FILE":
        return <LaunchIcon />
      case "MISC_FILE":
        return <InsertDriveFileIcon />
      case "WIFI_FILE":
        return <WifiIcon />
      case "CONFIG_FILE":
        return <SettingsIcon />
      default:
        return <CodeIcon />
    }
  }

  render(){
    const {updateAttending, updateDone} = this.props ;
    const optionsCreatedDate = {year: 'numeric', month: '2-digit', day: '2-digit', hour:'2-digit',minute:'2-digit', second:'2-digit' };

    return(
      <div>
        <List subheader="Mise à jours en attente">
        {updateAttending.map((update) => (
          <ListItem key={update.fileLink.fileName}>
            <ListItemAvatar>
              <Avatar>
                {this.getIcon(update.fileLink.fileType)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={update.fileLink.fileName} secondary={
              <React.Fragment>
                  <Link>{update.fileLink.link}</Link> <br/>
                  créée le {new Date(Date.parse(update.createdDate)).toLocaleDateString('fr-FR',optionsCreatedDate)} <br/>
              </React.Fragment>
            } />
          </ListItem>
        ))}
        </List>

      <Divider />
      <br/>

      <List subheader="Mise à jours réalisées">
      {updateDone.map((update) => (
        <ListItem key={update.fileLink.fileName}>
          <ListItemAvatar>
            <Avatar>
              {this.getIcon(update.fileLink.fileType)}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={update.fileLink.fileName} secondary={
            <React.Fragment>
              <Link href={update.fileLink.link}>{update.fileLink.link}</Link> <br/>
              créée le {new Date(Date.parse(update.createdDate)).toLocaleDateString('fr-FR',optionsCreatedDate)} <br/>
              réalisée le {new Date(Date.parse(update.confirmationDate)).toLocaleDateString('fr-FR',optionsCreatedDate)}
            </React.Fragment>
        }>
          </ListItemText>
        </ListItem>
      ))}
      </List>
    </div>

    );
  }
}
