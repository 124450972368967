import * as types from "../actions";

export const initialState = {
    courseSessions: [],
    courseYearGroup: undefined,
    startDate: undefined,
    endDate: undefined,
    errorMessage: undefined,
    isFetching: false,
    courseSessionsSaved: undefined,
    courseSessionsUnsaved: undefined,
    courseSessionsSaveErrorMessage: undefined,
    isSending: false,
    studentCourseSessions: []
};

export const CourseSessionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_COURSESESSIONS_REQUEST:
            return Object.assign({}, state, {
                courseSessions: [],
                errorMessage: undefined,
                isFetching: true,
                courseSessionsSaved: undefined,
                courseSessionsUnsaved: undefined,
                courseSessionsSaveErrorMessage: undefined
            });
        case types.FETCH_COURSESESSIONS_SUCCESS:
            return Object.assign({}, state, {
                courseSessions: adaptCourseSessionsFormat(action.courseSessions),
                errorMessage: undefined,
                isFetching: false,
                courseSessionsSaved: undefined,
                courseSessionsUnsaved: undefined,
                courseSessionsSaveErrorMessage: undefined
            });
        case types.FETCH_COURSESESSIONS_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.errorMessage,
                isFetching: false,
                courseSessionsSaved: undefined,
                courseSessionsUnsaved: undefined,
                courseSessionsSaveErrorMessage: undefined
            });
        case types.SEND_COURSESESSIONS_REQUEST:
            return Object.assign({}, state, {
                isSending: true,
                courseSessionsSaveErrorMessage: undefined
            });
        case types.SEND_COURSESESSIONS_SUCCESS:
            return Object.assign({}, state, {
                errorMessage: undefined,
                isSending: false,
                courseSessions: adaptCourseSessionsFormat(mergeCourseSessions(action.courseSessionsSuccessResult.savedCourseSessions, action.courseSessionsSuccessResult.unsavedCourseSessions)),
                courseSessionsSaved: action.courseSessionsSuccessResult.savedCourseSessions,
                courseSessionsUnsaved: action.courseSessionsSuccessResult.unsavedCourseSessions,
                courseSessionsSaveErrorMessage: undefined
            });
        case types.SEND_COURSESESSIONS_FAILURE:
            return Object.assign({}, state, {
                errorMessage: undefined,
                isSending: false,
                courseSessions: adaptCourseSessionsFormat(mergeCourseSessions(action.courseSessionsFailedResult.savedCourseSessions, action.courseSessionsFailedResult.unsavedCourseSessions)),
                courseSessionsSaved: action.courseSessionsFailedResult.savedCourseSessions,
                courseSessionsUnsaved: action.courseSessionsFailedResult.unsavedCourseSessions,
                courseSessionsSaveErrorMessage: action.errorMessage
            });
        case types.FETCH_STUDENTCOURSESESSIONS_REQUEST:
            return Object.assign({}, state, {
                errorMessage: undefined,
                isFetching: true,
                studentCourseSessions: []
            });
        case types.FETCH_STUDENTCOURSESESSIONS_SUCCESS:
            return Object.assign({}, state, {
                errorMessage: undefined,
                isFetching: false,
                studentCourseSessions: adaptCourseSessionsWithNoWrapper(action.courseSessions)
            });
        case types.FETCH_STUDENTCOURSESESSIONS_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.errorMessage,
                isFetching: false,
                studentCourseSessions: undefined
            });
        default:
            return state;
    }
}

function mergeCourseSessions(courseSessionsSaved, courseSessionsUnsaved) {
    if (!courseSessionsSaved) {
        if (!courseSessionsUnsaved) {
            return [];
        } else {
            return courseSessionsUnsaved;
        }
    } else if (!courseSessionsUnsaved) {
        return courseSessionsSaved;
    } else {
        return courseSessionsSaved.concat(courseSessionsUnsaved);
    }

}

function adaptCourseSessionsFormat(rawCourseSessions) {
    return rawCourseSessions.map(courseSessionWithErrors => {
        var event = {};
        event.title = courseSessionWithErrors.courseSession.discipline;
        event.start = new Date(courseSessionWithErrors.courseSession.startTime);
        event.end = new Date(courseSessionWithErrors.courseSession.endTime);
        event.confirmed = courseSessionWithErrors.courseSession.confirmed;
        event.errors = courseSessionWithErrors.errors;
        event.studentGroups = courseSessionWithErrors.courseSession.studentGroups;
        return event;
    });
}

function adaptCourseSessionsWithNoWrapper(rawCourseSessions) {
    return rawCourseSessions.map(courseSession => {
        var event = {};
        event.title = courseSession.discipline;
        event.start = new Date(courseSession.startTime);
        event.end = new Date(courseSession.endTime);
        return event;
    });
}