import * as types from "./DataManagementActions";

export const initialState = {
  isLoading: false,
  result: undefined,
  errorMessage: undefined
};

const DataManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SEND_DATA_REQUEST:
            return Object.assign({}, state, {
                isLoading: true,
                result: undefined,
                errorMessage: undefined
            });
        case types.SEND_DATA_SUCCESS:
            return Object.assign({}, state, {
                result: action.result,
                isLoading: false
            });
        case types.SEND_DATA_FAILURE:
            return Object.assign({}, state, {
                isLoading: false,
                errorMessage: action.errorMessage
            });
        default:
            return state;
    }
}

export default DataManagementReducer;
