import { connect } from "react-redux";

import StudentAccessPageView from "./StudentAccessPageView";
import { fetchSelfAttendanceRecords } from "../../../shared/redux/services";

const mapStateToProps = state => {
    return {
        isLoggedIn: state.LoginReducer.isLoggedIn,
        username: state.LoginReducer.username,
        attendanceRecords: state.RealTimeAttendancyReducer.attendanceRecords,
        attendanceRecordsRetrievalErrorMessage: state.RealTimeAttendancyReducer.attendanceRecordsRetrievalErrorMessage,
        isFetchingAttendanceRecords: state.RealTimeAttendancyReducer.isFetchingAttendanceRecords
    };
};

function mapDispatchToProps(dispatch) {
    return {
        sendGetAttendanceRecordsRequest: () => {
            dispatch(fetchSelfAttendanceRecords());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentAccessPageView);