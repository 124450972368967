import * as React from 'react';
import Typography from '@mui/material/Typography';

/* Props: 
        - message => string
*/
export function ErrorMessage(props) {
    if (props.displayIf && props.message) {
        return (
            <Typography variant="subtitle2" color="error">
                {props.message}
            </Typography>
        )
    } else {
        return null;
    }
}

ErrorMessage.defaultProps = {
    displayIf: true
};