import * as actions from "../actions";
import * as endpoints from "../api";
import { simpleCallService } from "../../utils/networking";

/** Send request to get card details for a given course year group */
export function fetchCardDetailsOfCourseYearGroup(courseYearGroup) {
	return simpleCallService(
		endpoints.CARD_DETAILS_ENDPOINT + "?courseYearGroup=" + courseYearGroup,
		"GET",
		() => actions.fetchCardDetailsRequest(courseYearGroup),
		actions.fetchCardDetailsSuccess,
		actions.fetchCardDetailsFailure,
		null,
		true
	);
}

/** Send request to get card owner */
export function fetchCardOwner(cardId) {
	return simpleCallService(
		endpoints.CARD_OWNER_ENDPOINT + "?cardId=" + cardId,
		"GET",
		() => actions.fetchCardOwnerRequest(cardId),
		actions.fetchCardOwnerSuccess,
		actions.fetchCardDetailsFailure,
		null,
		true
	);
}

/** Send request to dissociate a card */
export function dissociateCard(cardId) {
	return simpleCallService(
		endpoints.DISSOCIATE_CARD_ENDPOINT + "?cardId=" + cardId,
		"PUT",
		() => actions.dissociateCardRequest(cardId),
		actions.dissociateCardSuccess,
		actions.dissociateCardFailure,
		null,
		false
	);
}

/** Send request to reaffect a card */
export function reaffectCard(cardId, attenderId) {
	return simpleCallService(
		endpoints.REAFFECT_CARD_ENDPOINT + "?cardId=" + cardId + "&attenderId=" + attenderId,
		"PUT",
		() => actions.reaffectCardRequest(cardId, attenderId),
		actions.reaffectCardSuccess,
		actions.reaffectCardFailure,
		null,
		false
	);
}

/** Send request to affect a new card */
export function affectCard(cardId, attenderId) {
	console.log(attenderId);
	return simpleCallService(
		endpoints.AFFECT_CARD_ENDPOINT + "?cardId=" + cardId + "&attenderId=" + attenderId,
		"PUT",
		() => actions.affectCardRequest(cardId, attenderId),
		actions.affectCardSuccess,
		actions.affectCardFailure,
		null,
		false
	);
}
