import React from 'react';
import Typography from '@material-ui/core/Typography'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import SettingsIcon from '@material-ui/icons/Settings';

import { SimpleDialog } from '../../components';

export default class TopBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMenu: false,
            disconnected: undefined,
            showContactModal: false
        };
    }

    handleMenu = event => {
        this.setState({showMenu: true, anchor: event.currentTarget});
    };
    
    handleClose = () => {
        this.setState({showMenu: false});
    };

    handleLoggedOut = () => {
        localStorage.clear();
        this.setState({showMenu: false, disconnected: true});
        this.props.logOut();
    };

    toggleContactUs = () => {
        this.setState({showContactModal: !this.state.showContactModal});
    }

    getContactUsModalContent = () => {
        return {
            title: "Formulaire de contact",
            text: "Cette fonction n'est pas encore disponible, veuillez contacter un administrateur directement par e-mail à support@alumneo.fr."
        };
    }

    render() {
        return (
            <AppBar position="fixed" style={{zIndex: 1201, flexGrow: 1}}>
                <Toolbar>
                    <Typography variant="h6" style={{flexGrow: 1, textAlign: "left"}}>
                        Plateforme Alumneo de l'ITII PdL
                    </Typography>
                    <Button color="inherit" onClick={this.handleMenu}>{this.props.username}<SettingsIcon style={{marginLeft: "3px"}} /></Button>
                    <Menu
                        id="menu-appbar"
                        anchorEl={this.state.anchor}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        keepMounted
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={this.state.showMenu}
                        onClose={this.handleClose}
                    >
                        <MenuItem onClick={this.toggleContactUs}>Nous contacter</MenuItem>
                        <MenuItem onClick={this.handleLoggedOut}>Se Déconnecter</MenuItem>
                    </Menu>
                </Toolbar>
                {this.state.showContactModal ? 
                    <SimpleDialog
                        onClose={this.toggleContactUs}
                        open={this.state.showContactModal}
                        content={this.getContactUsModalContent()}
                    />
                : null}
            </AppBar>
        );
    }
}