import React, { useState } from "react";
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/fr';

import CircularProgress from '@mui/material/CircularProgress';

import AttendancyEventModal from "./AttendancyEventModal";
import PaperSheet from "../../../shared/components/PaperSheet";

import 'react-big-calendar/lib/css/react-big-calendar.css';

const messages = {
    allDay: 'Journée',
    previous: 'Précédent',
    next: 'Suivant',
    today: 'Aujourd\'hui',
    week: 'Semaine',
    work_week: 'Semaine de travail',
    day: 'Jour',
    agenda: 'Agenda',
    date: 'Date',
    time: 'Heure',
    event: 'Cours',
    showMore: total => `+ ${total} cours supplémentaire(s)`
}

export default function RealTimeAttendancyCalendar(props) {
    const [showModal, setShowModal] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const handleModalClose = () => {
        setOpen(false);
        setShowModal(false);
        setSelectedEvent(null);
    };

    const handleClickOnEvent = (event) => {
        setSelectedEvent(event);
        setOpen(true);
        setShowModal(true);
    }

    const handleChangeOnCalendar = (currentDateRange, newView) => {
        const startDate = currentDateRange[0];
        const endDate = new Date(currentDateRange[currentDateRange.length - 1]);
        endDate.setDate(endDate.getDate() + 1);

        props.handleNewDateRange(startDate, endDate);
    }

    // Color: https://www.w3schools.com/colors/colors_picker.asp
    const getEventStyle = (event) => {
        if (event.allStudentsPresent) {
            return { style: { backgroundColor: "#229954", color: "#FFFFFF", border: "0px" } }; // Green (40%)
        } else if (event.isOccurring) {
            return { style: { backgroundColor: "#2646a6", color: "#FFFFFF", border: "0px" } }; // Blue (40%)
        } else if (event.allStudentsAbsent) {
            return { style: { backgroundColor: "#a93226", color: "#FFFFFF", border: "0px" } }; // Red (40%)
        } else {
            return { style: { backgroundColor: "#2471a3", color: "#FFFFFF", border: "0px" } }; // Yellow (40%)
        }
    }
    
    const calendarStyle = (props.isFetchingRealTimeAttendancy ? { height: "80vh", opacity: 0.50, pointerEvents: "none" } : { height: "80vh" });
    return (
        <PaperSheet>
            <div style={calendarStyle}>
                <Calendar
                    events={props.events}
                    views={['week']}
                    defaultView={Views.WEEK}
                    defaultDate={props.defaultDate}
                    culture={"fr"}
                    localizer={momentLocalizer(moment)}
                    messages={messages}
                    min={moment('06:00am', 'h:mma').toDate()}
                    max={moment('10:00pm', 'h:mma').toDate()}
                    onRangeChange={(a, b) => handleChangeOnCalendar(a, b)}
                    eventPropGetter={(e) => getEventStyle(e)}
                    onSelectEvent={(e) => handleClickOnEvent(e)}
                />
            </div>
            {props.isFetchingRealTimeAttendancy ?
                <div style={{ position: "relative", top: "-60vh" }}>
                    <CircularProgress />
                </div>
            : null}
            {showModal ? 
                <AttendancyEventModal 
                    open={open}
                    onClose={handleModalClose}
                    event={selectedEvent}
                />
            : null}
        </PaperSheet>
    );
}
