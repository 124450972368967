import { authenticationFailure } from "../../../../shared/redux/actions";
import { fetchWithTimeOut } from "../../../../shared/utils/networking";
import { MAIN_SERVER_URL } from "../../../../shared/config/index.js";
import { fetchAbsencesStatsForCourseYearGroupInPeriodRequest, fetchAbsencesStatsForCourseYearGroupInPeriodSuccess, fetchAbsencesStatsForCourseYearGroupInPeriodFailure,
        sendCreateAttendanceCertificatesRequest, sendCreateAttendanceCertificatesSuccess, sendCreateAttendanceCertificatesFailure } from "./AttendanceCertificatesActions";

/** Send request to get stats about students absences for a course year group during a specific period */
export function fetchAbsencesStatsForCourseYearGroupInPeriod(courseYearGroup, startingDate, endingDate) {
    return function (dispatch) {
        dispatch(fetchAbsencesStatsForCourseYearGroupInPeriodRequest(courseYearGroup, startingDate, endingDate));

        const url = MAIN_SERVER_URL + "absences/students-stats";
        const token = localStorage.token;

        return fetchWithTimeOut(url, { method: "GET", headers: { "Authorization": token } })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw response;
                }
            })
            .then(json => {
                console.log("success");
                dispatch(fetchAbsencesStatsForCourseYearGroupInPeriodSuccess(DATA));
            })
            .catch(error => {
                if (error.status === 403) {
                    localStorage.clear();
                    dispatch(authenticationFailure("Votre session a expiré, veuillez vous reconnecter."));
                } else {
                    dispatch(fetchAbsencesStatsForCourseYearGroupInPeriodFailure("Une erreur est survenue. Veuillez contacter un administrateur."));
                }
            });
    }
}

/** Send request to generate attendance certificates  */
export function createAttendanceCertificates(students, startingDate, endingDate) {
    return function (dispatch) {
        dispatch(sendCreateAttendanceCertificatesRequest(students, startingDate, endingDate));

        const url = MAIN_SERVER_URL + "absences/students-stats";
        const token = localStorage.token;

        return fetchWithTimeOut(url, { method: "GET", headers: { "Authorization": token } })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw response;
                }
            })
            .then(json => {
                dispatch(sendCreateAttendanceCertificatesSuccess(DATA2));
            })
            .catch(error => {
                if (error.status === 403) {
                    localStorage.clear();
                    dispatch(authenticationFailure("Votre session a expiré, veuillez vous reconnecter."));
                } else {
                    dispatch(sendCreateAttendanceCertificatesFailure("Une erreur est survenue. Veuillez contacter un administrateur."));
                }
            });
    }
}


const DATA = [
    { lastName: 'Bob', firstName: 'Ross', numberOfCourseSessions: "12", numberOfAbsences: "3", numberOfUnprocessedAbsences: "1" },
    { lastName: 'Mister', firstName: 'Roger', numberOfCourseSessions: "15", numberOfAbsences: "2", numberOfUnprocessedAbsences: "0"},
    { lastName: 'Bob', firstName: 'Ross', numberOfCourseSessions: "12", numberOfAbsences: "3", numberOfUnprocessedAbsences: "1" },
    { lastName: 'Mister', firstName: 'Roger', numberOfCourseSessions: "15", numberOfAbsences: "2", numberOfUnprocessedAbsences: "0"},
    { lastName: 'Bob', firstName: 'Ross', numberOfCourseSessions: "12", numberOfAbsences: "3", numberOfUnprocessedAbsences: "1" },
    { lastName: 'Mister', firstName: 'Roger', numberOfCourseSessions: "15", numberOfAbsences: "2", numberOfUnprocessedAbsences: "0"},
    { lastName: 'Bob', firstName: 'Ross', numberOfCourseSessions: "12", numberOfAbsences: "3", numberOfUnprocessedAbsences: "1" },
    { lastName: 'Mister', firstName: 'Roger', numberOfCourseSessions: "15", numberOfAbsences: "2", numberOfUnprocessedAbsences: "0"}
];

const DATA2 = [
    { lastName: 'Bob', firstName: 'Ross', generated: false, error: "Une terrible erreur s'est produite" },
    { lastName: 'Laot', firstName: 'Rémi', generated: false, error: "L'élève avait trop absence, nous avons préféré ne pas créer une attestation"},
    { lastName: 'Bob', firstName: 'Ross', generated: true , url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Mister', firstName: 'Roger', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier"},
    { lastName: 'Bob', firstName: 'Ross', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Mister', firstName: 'Roger', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Bob', firstName: 'Ross', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Mister', firstName: 'Roger', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Bob', firstName: 'Ross', generated: true , url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Mister', firstName: 'Roger', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier"},
    { lastName: 'Bob', firstName: 'Ross', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Mister', firstName: 'Roger', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Bob', firstName: 'Ross', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Mister', firstName: 'Roger', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Bob', firstName: 'Ross', generated: true , url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Mister', firstName: 'Roger', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier"},
    { lastName: 'Bob', firstName: 'Ross', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Mister', firstName: 'Roger', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Bob', firstName: 'Ross', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Mister', firstName: 'Roger', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Bob', firstName: 'Ross', generated: true , url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Mister', firstName: 'Roger', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier"},
    { lastName: 'Bob', firstName: 'Ross', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Mister', firstName: 'Roger', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Bob', firstName: 'Ross', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Mister', firstName: 'Roger', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Bob', firstName: 'Ross', generated: true , url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Mister', firstName: 'Roger', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier"},
    { lastName: 'Bob', firstName: 'Ross', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Mister', firstName: 'Roger', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Bob', firstName: 'Ross', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Mister', firstName: 'Roger', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Bob', firstName: 'Ross', generated: true , url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Mister', firstName: 'Roger', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier"},
    { lastName: 'Bob', firstName: 'Ross', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Mister', firstName: 'Roger', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Bob', firstName: 'Ross', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Mister', firstName: 'Roger', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Bob', firstName: 'Ross', generated: true , url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Mister', firstName: 'Roger', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier"},
    { lastName: 'Bob', firstName: 'Ross', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Mister', firstName: 'Roger', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Bob', firstName: 'Ross', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Mister', firstName: 'Roger', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Bob', firstName: 'Ross', generated: true , url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Mister', firstName: 'Roger', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier"},
    { lastName: 'Bob', firstName: 'Ross', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Mister', firstName: 'Roger', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Bob', firstName: 'Ross', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" },
    { lastName: 'Mister', firstName: 'Roger', generated: true, url: "https://superurldus3.notresite.com/superendpoint/cefichier" }
];
