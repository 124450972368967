import React, { useState } from "react";

import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { ActionableDialog } from '../../../../shared/components';

export function CreateMotiveModal(props) {
    const [hasSentRequest, setHasSentRequest] = useState(false);
    const [selectedMotive, setSelectedMotive] = useState('');
    const [complementaryNote, setComplementaryNote] = useState('');
    const [justified, setJustified] = useState(false);

    const handleMotiveChange = (e) => {
        setSelectedMotive(e.target.value);
    }

    const handleNoteChange = (e) => {
        setComplementaryNote(e.target.value);
    }

    const handleJustifiedChange = (e) => {
        setJustified(e.target.checked);
    }

    const handleSendRequest = () => {
        let absenceIds = [];
        if (props.data[0].absences) {
            absenceIds = props.data.flatMap(row => row.absences).map(absence => absence.absenceId);
        } else {
            absenceIds = props.data.map(absence => absence.id);
        }

        if (absenceIds) {
            setHasSentRequest(true);
            props.sendRequest(absenceIds, selectedMotive, complementaryNote, justified);
        }
    }

    const getContent = () => {
        return {
            title: "Appliquer un motif",
            text: getSelect()
        }
    }

    const isApplyingMotiveToMultipleStudents = () => {
        const nameSet = new Set(props.data.map(row => row.lastName + row.firstName));
        return nameSet.size > 1;
    }
    
    const getSelect = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', maxWidth: '500px'}}>
                { isApplyingMotiveToMultipleStudents() ? 
                    <Alert severity="warning">Attention, vous êtes sur le point d'appliquer un même motif à plusieurs étudiants.</Alert>
                    : null
                }
                <Typography>
                    Veuillez sélectionner un motif, ajouter un commentaire (optionnel), indiquer si l'absence est justifié ou non, puis confirmer:
                </Typography>
                <FormControl variant="standard" sx={{ minWidth: 400 }}>
                    <InputLabel id="motive-type-select-label">Motif de l'absence</InputLabel>
                    <Select
                        labelId="motive-type-select-label"
                        id="motive-type-select"
                        value={selectedMotive}
                        onChange={handleMotiveChange}
                        label="Raison de la suppression"
                    >
                        { props.motiveTypes ? getMotiveTypeMap() : null }
                    </Select>
                </FormControl>
                <TextField id="complementary-note" variant="standard"
                    label="Commentaire (optionnel)"
                    value={complementaryNote}
                    onChange={handleNoteChange}
                />
                <FormControlLabel
                    control={<Checkbox checked={justified} onChange={handleJustifiedChange} />}
                    label="Justificatif transmis"
                />
                <LoadingButton
                    variant="contained"
                    color="primary"
                    disabled={!selectedMotive || props.success || props.failure}
                    onClick={handleSendRequest}
                    loading={props.isLoading}
                >
                    Enregistrer
                </LoadingButton>
                { props.success
                    ? <Alert severity="success">Les absences ont bien été traitées et les motifs enregistrés.</Alert>
                    : null
                }
                { props.failure
                    ? <Alert severity="error">Une erreur s'est produite, veuillez contacter un administrateur à admin@alumneo.fr.</Alert>
                    : null
                }
            </div>
        );
    }

    const getMotiveTypeMap = () => {
        const categoryToTypeMap = new Map();
        categoryToTypeMap.set("LEGAL", [<ListSubheader key={"Légaux"}>{"Légaux"}</ListSubheader>]);
        categoryToTypeMap.set("MISC", [<ListSubheader key={"Autre"}>{"Autre"}</ListSubheader>]);

        Object.entries(props.motiveTypes).sort(sortMotives).forEach(([motiveType, description], index) => {
            if (motiveType.startsWith("ERROR")) return;

            let categoryComponent = [];
            if (motiveType.startsWith("MISC")) {
                categoryComponent = categoryToTypeMap.get("MISC");
            } else {
                categoryComponent = categoryToTypeMap.get("LEGAL");
            }

            categoryComponent.push(<MenuItem key={index} value={motiveType}> {description} </MenuItem>);
        });

        return Array.from(categoryToTypeMap.values()).flat();
    }

    return (
        <ActionableDialog
            open={props.open}
            onClose={() => props.onClose(hasSentRequest)}
            content={getContent()}
        />
    );
}

const sortMotives = ([motiveType1, description1], [motiveType2, description2]) => {
    return description1 > description2;
}