import * as React from 'react';

import { SnackBarMessage } from './SnackBarMessage';

/* Props: 
        - messages => array(string)
        - severity => string (error, warning, info, success).
*/
export function SnackBarMessageGroup(props) {
    if (props.messages) {
        for (const message of props.messages) {
            if (message) {
                return (
                    <SnackBarMessage 
                        message={message}
                        severity={props.severity}
                    />
                ); 
            }
        }
    }
    return null;
}