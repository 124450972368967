import * as types from "../actions";

export const initialState = {
    errorMessage: undefined,
    isSuccessful: false,
    isFetching: false
};

export const PasswordResetReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.PASSWORDRESET_REQUEST:
        case types.NEWPASSWORD_REQUEST:
            return Object.assign({}, state, {
                errorMessage: undefined,
                isSuccessful: false,
                isFetching: true
            });
        case types.PASSWORDRESET_SUCCESS:
        case types.NEWPASSWORD_SUCCESS:
            return Object.assign({}, state, {
                errorMessage: undefined,
                isSuccessful: true,
                isFetching: false
            });
        case types.NEWPASSWORD_FAILURE:
        case types.PASSWORDRESET_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.errorMessage,
                isSuccessful: false,
                isFetching: false
            });
        default:
            return state;
    }
}