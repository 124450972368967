/* Fetch real time attendancy for a course year group */

export const FETCH_REALTIMEATTENDANCY_REQUEST = "FETCH_REALTIMEATTENDANCY_REQUEST";
export const FETCH_REALTIMEATTENDANCY_SUCCESS = "FETCH_REALTIMEATTENDANCY_SUCCESS";
export const FETCH_REALTIMEATTENDANCY_FAILURE = "FETCH_REALTIMEATTENDANCY_FAILURE";

export const feachRealTimeAttendancyRequest = () => ({
    type: FETCH_REALTIMEATTENDANCY_REQUEST
});

export const fetchRealtimeAttendancySuccess = (realtimeAttendancy) => ({
    type: FETCH_REALTIMEATTENDANCY_SUCCESS,
    realtimeAttendancy: realtimeAttendancy
});

export const fetchRealtimeAttendancyFailure = (errorMessage) => ({
    type: FETCH_REALTIMEATTENDANCY_FAILURE,
    errorMessage: errorMessage
});


/* Create attendance request for 1 or more students */

export const CREATE_ATTENDANCERECORD_REQUEST = "CREATE_ATTENDANCERECORD_REQUEST";
export const CREATE_ATTENDANCERECORD_SUCCESS = "CREATE_ATTENDANCERECORD_SUCCESS";
export const CREATE_ATTENDANCERECORD_FAILURE = "CREATE_ATTENDANCERECORD_FAILURE";

export const createAttendanceRecordRequest = () => ({
    type: CREATE_ATTENDANCERECORD_REQUEST
});

export const createAttendanceRecordSuccess = () => ({
    type: CREATE_ATTENDANCERECORD_SUCCESS
});

export const createAttendanceRecordFailure = (errorMessage) => ({
    type: CREATE_ATTENDANCERECORD_FAILURE,
    errorMessage: errorMessage
});


/* Fetch attendance records of a student */

export const FETCH_STUDENT_ATTENDANCE_RECORDS_REQUEST = "FETCH_STUDENT_ATTENDANCE_RECORDS_REQUEST";
export const FETCH_STUDENT_ATTENDANCE_RECORDS_SUCCESS = "FETCH_STUDENT_ATTENDANCE_RECORDS_SUCCESS";
export const FETCH_STUDENT_ATTENDANCE_RECORDS_FAILURE = "FETCH_STUDENT_ATTENDANCE_RECORDS_FAILURE";

export const fetchStudentAttendanceRecordsRequest = () => ({
    type: FETCH_STUDENT_ATTENDANCE_RECORDS_REQUEST
});

export const fetchStudentAttendanceRecordsSuccess = (attendanceRecords) => ({
    type: FETCH_STUDENT_ATTENDANCE_RECORDS_SUCCESS,
    attendanceRecords: attendanceRecords
});

export const fetchStudentAttendanceRecordsFailure = (errorMessage) => ({
    type: FETCH_STUDENT_ATTENDANCE_RECORDS_FAILURE,
    errorMessage: errorMessage
});