import * as React from 'react';

import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const useStyles = makeStyles({
    root: {
      backgroundColor: 'transparent !important'
    }
});

/* Props: 
        - handleStudentSelection            => function(string).
        - students                          => array: [{studentFirstName: string, studentLastName: string, studentId: Long}]).
        - selectedStudent                   => a student object.
        - disabled
*/
export function StudentPicker(props) {
    const [student, setStudent] = React.useState(props.selectedStudent || '');
    const classes = useStyles(props);
    
    const handleChange = (e) => {
        if (e.target.value) {
            setStudent(e.target.value);
            props.handleStudentSelection(e.target.value);
        }
    }

    return (
        <FormControl 
            variant="filled"
            sx={{ m: 1, minWidth: 240 }}
            disabled={props.disabled}
        >
            <InputLabel htmlFor="student-selection">Sélectionner un étudiant</InputLabel>
            <Select
                id="student-selection"
                onChange={handleChange}
                value={student}
                className={classes.root}
            >
                { props.students ? toComponents(props.students) : null }
            </Select>
        </FormControl>
    );
}

const toComponents = (students) => {
    return students.sort(sortStudent).map(student => <MenuItem value={student.studentId} key={student.studentId}>{student.studentLastName + " " + student.studentFirstName}</MenuItem> );
}

const sortStudent = (s1, s2) => {
    return s1.studentLastName > s2.studentLastName;
}