/* Fetch course sessions for a given course year group and date range */

export const FETCH_COURSESESSIONS_REQUEST = "FETCH_COURSESESSIONS_REQUEST";
export const FETCH_COURSESESSIONS_SUCCESS = "FETCH_COURSESESSIONS_SUCCESS";
export const FETCH_COURSESESSIONS_FAILURE = "FETCH_COURSESESSIONS_FAILURE";

export const fetchCourseSessionsRequest = () => ({
    type: FETCH_COURSESESSIONS_REQUEST
});

export const fetchCourseSessionsSuccess = (courseSessions) => ({
    type: FETCH_COURSESESSIONS_SUCCESS,
    courseSessions: courseSessions
});

export const fetchCourseSessionsFailure = (errorMessage) => ({
    type: FETCH_COURSESESSIONS_FAILURE,
    errorMessage: errorMessage
});

/* Send course sessions to be saved on the server side */

export const SEND_COURSESESSIONS_REQUEST = "SEND_COURSESSESSIONS_REQUEST";
export const SEND_COURSESESSIONS_SUCCESS = "SEND_COURSESSESSIONS_SUCCESS";
export const SEND_COURSESESSIONS_FAILURE = "SEND_COURSESSESSIONS_FAILURE";

export const sendCourseSessionsRequest = () => ({
    type: SEND_COURSESESSIONS_REQUEST
});

export const sendCourseSessionsSuccess = (courseSessionsSuccessResult) => ({
    type: SEND_COURSESESSIONS_SUCCESS,
    courseSessionsSuccessResult: courseSessionsSuccessResult
});

export const sendCourseSessionsFailure = (courseSessionsFailedResult, errorMessage) => ({
    type: SEND_COURSESESSIONS_FAILURE,
    courseSessionsFailedResult: courseSessionsFailedResult,
    errorMessage: errorMessage
});

/* Fetch student required course sessions for a given date range */

export const FETCH_STUDENTCOURSESESSIONS_REQUEST = "FETCH_STUDENTCOURSESESSIONS_REQUEST";
export const FETCH_STUDENTCOURSESESSIONS_SUCCESS = "FETCH_STUDENTCOURSESESSIONS_SUCCESS";
export const FETCH_STUDENTCOURSESESSIONS_FAILURE = "FETCH_STUDENTCOURSESESSIONS_FAILURE";

export const fetchStudentCourseSessionsRequest = () => ({
    type: FETCH_STUDENTCOURSESESSIONS_REQUEST
});

export const fetchStudentCourseSessionsSuccess = (courseSessions) => ({
    type: FETCH_STUDENTCOURSESESSIONS_SUCCESS,
    courseSessions: courseSessions
});

export const fetchStudentCourseSessionsFailure = (errorMessage) => ({
    type: FETCH_STUDENTCOURSESESSIONS_FAILURE,
    errorMessage: errorMessage
});