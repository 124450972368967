import React from "react";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import LoggedInLayout from "../../../shared/layouts/logged-in/LoggedInLayout";
import PaperSheet from "../../../shared/components/PaperSheet";
import { DATA_REQUESTS } from "../data-management-page/redux/DataManagementServices";

export default class ActuatorsView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedActuator: "",
            availableActuators: []
        };
    }

    componentDidMount() {
        this.props.sendActuatorsRequest("actuator");
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.result && this.props.result && this.state.selectedActuator === "") {
            console.log("new avaiable actuators");
            this.setState({
                availableActuators: this.extractAvailableActuators(this.props.result)
            });
        }
    }

    extractAvailableActuators(result) {
        const regexp = /actuator\/\w*/g;
        return [...new Set(result.match(regexp))];
    }

    handleActuatorSelection(actuator) {
        if (actuator !== this.state.selectedActuator) {
            this.setState({
                selectedActuator: actuator
            });
            this.props.sendActuatorsRequest(actuator);
        }
    }

    render() {
        return (
            <LoggedInLayout isLoggedIn={this.props.isLoggedIn} username={this.props.username}>
                <PaperSheet>
                    <div style={{ display: "flex", alignItems: "center", minHeight: "500px", justifyContent: "space-between", flexDirection: "column"}}>
                        <form autoComplete="off">
                            <FormControl style={{ minWidth: '200px' }}>
                                <InputLabel shrink htmlFor="type">Type</InputLabel>
                                <Select
                                    value={this.state.type}
                                    onChange={(e) => this.handleTypeSelection(e.target.value)}
                                >
                                    {[...DATA_REQUESTS.keys()].map((label, index) => {
                                        return (
                                            <MenuItem value={label} key={index}>{label}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </form>
                        <TextField
                            id="body"
                            label="Body"
                            multiline
                            rowsMax="15"
                            value={this.state.text}
                            onChange={(e) => this.handleChangeInTextField(e.target.value)}
                            margin="normal"
                            style={{minWidth:"500px"}}
                        />
                        <Button variant="contained" onClick={() => this.handleValidation()}>
                            Valider
                        </Button>
                        <Typography>
                            {this.props.errorMessageSending ? this.props.errorMessageSending
                                : (this.props.resultSending ? this.props.resultSending : null)}
                        </Typography>
                    </div>
                </PaperSheet>
                <PaperSheet>
                    <form autoComplete="off">
                        <FormControl style={{ minWidth: '200px' }}>
                            <InputLabel shrink htmlFor="actuator">Actuator</InputLabel>
                            <Select
                                value={this.state.selectedActuator}
                                onChange={(e) => this.handleActuatorSelection(e.target.value)}
                            >
                                {this.state.availableActuators ? this.state.availableActuators.map((label, index) => {
                                    return (
                                        <MenuItem value={label} key={index}>{label}</MenuItem>
                                    );
                                }) : null}
                            </Select>
                        </FormControl>
                    </form>
                    <div style={{ textAlign: "left", whiteSpace: "pre-line", marginTop: "15px", maxWidth: "1600px", overflowWrap: "break-word" }}>
                        <Typography>
                            {this.props.result && this.state.selectedActuator !== "" ? this.props.result : null}
                        </Typography>
                    </div>
                </PaperSheet>
            </LoggedInLayout>
        );
    }

}