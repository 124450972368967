import * as React from 'react';

import { SnackBarMessageGroup } from './SnackBarMessageGroup';

/* Props: 
        - messages => array(string)
*/
export function SnackBarErrorMessageGroup(props) {
    return (
        <SnackBarMessageGroup
            messages={props.messages}
            severity={"error"}
        />
    );
}