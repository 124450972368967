export const LOGIN_ENDPOINT                             = "login";
export const USER_ENDPOINT                              = "users";
export const ACCESSIBLE_COURSE_YEAR_GROUPS_ENDPOINT     = USER_ENDPOINT + "/course-year-groups";
export const ACCESSIBLE_FEATURES_ENDPOINT               = USER_ENDPOINT + "/available-features";
export const RESET_PASSWORD_REQUEST_ENDPOINT            = USER_ENDPOINT + "/reset-password-request";
export const RESET_PASSWORD_ENDPOINT                    = USER_ENDPOINT + "/reset-password";

export const CORE_DATA_ENDPOINT                         = "data-management";
export const STUDENTS_OF_COURSE_YEAR_GROUP_ENDPOINT     = CORE_DATA_ENDPOINT + "/students-of-course-year-group";

export const ATTENDANCY_ENDPOINT                        = "attendancy";
export const ATTENDANCE_RECORD_CREATE_ENDPOINT          = ATTENDANCY_ENDPOINT + "/create-records";
export const WEEKLY_ATTENDANCY_ENDPOINT                 = ATTENDANCY_ENDPOINT + "/real-time-in-week";
export const WEEKLY_ATTENANCY_WITH_SCHEDULE_ENDPOINT    = ATTENDANCY_ENDPOINT + "/real-time-in-week-with-schedule";
export const SELF_ATTENDANCE_RECORDS_ENDPOINT           = ATTENDANCY_ENDPOINT + "/of-self";

export const ABSENCE_ENDPOINT                           = "absences";
export const ABSENCE_GET_CONSECUTIVE_ENDPOINT           = ABSENCE_ENDPOINT + "/of-course-year-group/consecutive";
export const ABSENCE_GET_MOTIVE_TYPES_ENDPOINT          = ABSENCE_ENDPOINT + "/absence-motive-types";
export const ABSENCE_CREATE_ENDPOINT                    = ABSENCE_ENDPOINT + "/create";
export const ABSENCE_UPDATE_MOTIVE_ENDPOINT             = ABSENCE_ENDPOINT + "/update-motive";
export const ABSENCE_REMOVE_MOTIVE_ENDPOINT             = ABSENCE_ENDPOINT + "/remove-motive";

export const STUDENT_ATTENDANCE_MONITORING_ENDPOINT     = "student-attendance-monitoring";
export const COURSE_YEAR_GROUP_STATS_ENDPOINT           = STUDENT_ATTENDANCE_MONITORING_ENDPOINT + "/of-course-year-group";
export const MONTHLY_COURSE_YEAR_GROUP_STATS_ENDPOINT   = STUDENT_ATTENDANCE_MONITORING_ENDPOINT + "/by-month";

export const COURSE_SESSION_ENDPOINT                    = "course-session";
export const COURSE_SESSIONS_OF_STUDENT_ENDPOINT        = COURSE_SESSION_ENDPOINT + "/validated-by-student-range";

export const CARD_ENDPOINT                              = "idcard";
export const CARD_OWNER_ENDPOINT                        = CARD_ENDPOINT + "/owner";
export const CARD_DETAILS_ENDPOINT                      = CARD_ENDPOINT + "/of-course-year-group";
export const DISSOCIATE_CARD_ENDPOINT                   = CARD_ENDPOINT + "/dissociate";
export const REAFFECT_CARD_ENDPOINT                     = CARD_ENDPOINT + "/reaffect";
export const AFFECT_CARD_ENDPOINT                       = CARD_ENDPOINT + "/affect";