/* Fetch Card details for a course year group */

export const FETCH_CARDETAILS_REQUEST = "FETCH_CARDETAILS_REQUEST";
export const FETCH_CARDETAILS_SUCCESS = "FETCH_CARDETAILS_SUCCESS";
export const FETCH_CARDETAILS_FAILURE = "FETCH_CARDETAILS_FAILURE";

export const fetchCardDetailsRequest = (courseYearGroup) => ({
    type: FETCH_CARDETAILS_REQUEST,
    courseYearGroup: courseYearGroup
});

export const fetchCardDetailsSuccess = (cardDetails) => ({
    type: FETCH_CARDETAILS_SUCCESS,
    cardDetails: cardDetails
});

export const fetchCardDetailsFailure = (errorMessage) => ({
    type: FETCH_CARDETAILS_FAILURE,
    errorMessage: errorMessage
});

/* Fetch card owner for a given card */

export const FETCH_CARDOWNER_REQUEST = "FETCH_CARDOWNER_REQUEST";
export const FETCH_CARDOWNER_SUCCESS = "FETCH_CARDOWNER_SUCCESS";
export const FETCH_CARDOWNER_FAILURE = "FETCH_CARDOWNER_FAILURE";

export const fetchCardOwnerRequest = (cardId) => ({
    type: FETCH_CARDOWNER_REQUEST,
    cardId: cardId
});

export const fetchCardOwnerSuccess = (cardOwner) => ({
    type: FETCH_CARDOWNER_SUCCESS,
    cardOwner: cardOwner
});

export const fetchCardOwnerFailure = (errorMessage) => ({
    type: FETCH_CARDOWNER_FAILURE,
    errorMessage: errorMessage
});

/* Dissociate card */

export const DISSOCIATE_CARD_REQUEST = "DISSOCIATE_CARD_REQUEST";
export const DISSOCIATE_CARD_SUCCESS = "DISSOCIATE_CARD_SUCCESS";
export const DISSOCIATE_CARD_FAILURE = "DISSOCIATE_CARD_FAILURE";

export const dissociateCardRequest = (cardId) => ({
    type: DISSOCIATE_CARD_REQUEST,
    cardId: cardId
});

export const dissociateCardSuccess = () => ({
    type: DISSOCIATE_CARD_SUCCESS
});

export const dissociateCardFailure = (errorMessage) => ({
    type: DISSOCIATE_CARD_FAILURE,
    errorMessage: errorMessage
});


/* Affect Card */

export const AFFECT_CARD_REQUEST = "AFFECT_CARD_REQUEST";
export const AFFECT_CARD_SUCCESS = "AFFECT_CARD_SUCCESS";
export const AFFECT_CARD_FAILURE = "AFFECT_CARD_FAILURE";

export const affectCardRequest = (cardId, attenderId) => ({
    type: AFFECT_CARD_REQUEST,
    cardId: cardId,
    attenderId: attenderId
});

export const affectCardSuccess = () => ({
    type: AFFECT_CARD_SUCCESS
});

export const affectCardFailure = (errorMessage) => ({
    type: AFFECT_CARD_FAILURE,
    errorMessage: errorMessage
});

/* Reaffect card */

export const REAFFECT_CARD_REQUEST = "REAFFECT_CARD_REQUEST";
export const REAFFECT_CARD_SUCCESS = "REAFFECT_CARD_SUCCESS";
export const REAFFECT_CARD_FAILURE = "REAFFECT_CARD_FAILURE";

export const reaffectCardRequest = (cardId, attenderId) => ({
    type: REAFFECT_CARD_REQUEST,
    cardId: cardId,
    attenderId: attenderId
});

export const reaffectCardSuccess = () => ({
    type: REAFFECT_CARD_SUCCESS
});

export const reaffectCardFailure = (errorMessage) => ({
    type: REAFFECT_CARD_FAILURE,
    errorMessage: errorMessage
});