export const SEND_DATA_REQUEST = "SEND_DATA_REQUEST";
export const SEND_DATA_SUCCESS = "SEND_DATA_SUCCESS";
export const SEND_DATA_FAILURE = "SEND_DATA_FAILURE";

export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";

export const sendDataRequest = () => ({
    type: SEND_DATA_REQUEST
});

export const sendDataSuccess = (result) => ({
    type: SEND_DATA_SUCCESS,
    result: result
});

export const sendDataFailure = (errorMessage) => ({
    type: SEND_DATA_FAILURE,
    errorMessage: errorMessage
});

export const fetchDataRequest = () => ({
    type: FETCH_DATA_REQUEST
});

export const fetchDataSuccess = (result, requestType) => ({
    type: FETCH_DATA_SUCCESS,
    requestType : requestType,
    result: result
});

export const fetchDataFailure = (errorMessage) => ({
    type: FETCH_DATA_FAILURE,
    errorMessage: errorMessage
});