export const FETCH_CARDREADERS_REQUEST = "FETCH_CARDREADERS_REQUEST";
export const FETCH_CARDREADERS_SUCCESS = "FETCH_CARDREADERS_SUCCESS";
export const FETCH_CARDREADERS_FAILURE = "FETCH_CARDREADERS_FAILURE";

export const fetchCardReadersRequest = () => ({
    type: FETCH_CARDREADERS_REQUEST
});

export const fetchCardReadersSuccess = (cardReaders, dateRequest) => ({
    type: FETCH_CARDREADERS_SUCCESS,
    cardReaders: cardReaders,
    dateRequest: dateRequest

});

export const fetchCardReadersFailure = (errorMessage) => ({
    type: FETCH_CARDREADERS_FAILURE,
    errorMessage: errorMessage
});
