import { connect } from "react-redux";

import * as services from "../../../shared/redux/services";
import AbsencesHandlingView from "./AbsencesHandlingView";

const mapStateToProps = state => {
    return {
        isLoggedIn: state.LoginReducer.isLoggedIn,
        username: state.LoginReducer.username,
        courseYearGroups: state.SharedReducer.courseYearGroups,
        courseYearGroupsErrorMessage: state.SharedReducer.cygErrorMessage,
        students: state.SharedReducer.students,
        studentsErrorMessage: state.SharedReducer.studentsErrorMessage,
        isLoading: state.AbsencesReducer.isLoading,
        absences: state.AbsencesReducer.absences,
        motiveTypes: state.AbsencesReducer.motiveTypes,
        absenceErrorMessage: state.AbsencesReducer.errorMessage,
        isWorflowLoading: state.AbsencesReducer.isWorflowLoading,
        isWorkflowSuccess: state.AbsencesReducer.isWorkflowSuccess,
        isWorkflowFailure: state.AbsencesReducer.isWorkflowFailure
    };
};

function mapDispatchToProps(dispatch) {
    return {
        sendGetCourseYearGroupsRequest: () => {
            dispatch(services.fetchCourseYearGroups());
        },
        sendGetAbsenceMotiveTypesRequest: () => {
            dispatch(services.fetchAbsenceMotiveTypes());
        },
        sendGetAbsencesRequest: (courseYearGroup, isProcessed, isError) => {
            dispatch(services.fetchAbsencesOfCourseYearGroup(courseYearGroup, isProcessed, isError));
        },
        sendCreateAbsenceRequest: (studentId, startTime, endTime) => {
            dispatch(services.createAbsence(studentId, startTime, endTime));
        },
        sendDeleteAbsenceRequest: (absenceIds, motiveType) => {
            dispatch(services.updateMotiveForAbsences(absenceIds, motiveType));
        },
        sendCreateMotiveRequest: (absenceIds, motiveType, complementaryNote, justified, documentFileName, documentFileLink) => {
            dispatch(services.updateMotiveForAbsences(absenceIds, motiveType, complementaryNote, justified, documentFileName, documentFileLink));
        },
        sendCancelMotiveRequest: (absenceIds) => {
            dispatch(services.cancelMotiveForAbsences(absenceIds));
        },
        fetchStudentsRequest: (courseYearGroup) => {
            dispatch(services.fetchStudentsOfCourseYearGroup(courseYearGroup));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AbsencesHandlingView);
