import * as actions from "../actions";
import * as endpoints from "../api";
import { fetchWithTimeOut, simpleCallService } from "../../utils/networking";
import { MAIN_SERVER_URL } from "../../config/index.js";

/** Send authentication request to server */
export function fetchAuthentication(username, password) {
    return function (dispatch) {
        dispatch(actions.authenticationRequest(username, password));

        const loginBody = { username: username, password: password };
        const url = MAIN_SERVER_URL + "login";

        return fetchWithTimeOut(url, { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(loginBody) })
            .then(response => {
                if (response.status === 200 || response.status === 202) {
                    return response;
                } else {
                    throw response;
                }
            })
            .then(data => {
                localStorage.setItem("token", data.headers.get("Authorization"));
                localStorage.setItem("username", username);
                dispatch(fetchAvailableFeatures());
            })
            .catch(error => {
                console.log(error);
                if (error.status === 403) {
                    dispatch(actions.authenticationFailure("Le compte n'existe pas ou le mot de passe est incorrect."));
                } else {
                    dispatch(actions.authenticationFailure("Une erreur s'est produite, impossible de se connecter."));
                }
            });
    }
}

/** Send request to receive available features for current user */
function fetchAvailableFeatures() {
    return function (dispatch) {
        const token = localStorage.token;
        const url = MAIN_SERVER_URL + "users/available-features";

        return fetchWithTimeOut(url, { method: "GET", headers: { "Authorization": token } })
            .then(response => {
                if (response.status === 200 || response.status === 202) {
                    return response.json();
                } else {
                    throw response;
                }
            })
            .then(json => {
                localStorage.setItem("features", json);
                dispatch(actions.authenticationSuccess(localStorage.username));
            })
            .catch(error => {
                console.log(error);
                localStorage.removeItem("token");
                localStorage.removeItem("username");
                localStorage.removeItem("features");
                if (error.status === 403) {
                    dispatch(actions.authenticationFailure("Le compte n'a aucun droit associé, veuillez contacter un administrateur."));
                } else {
                    dispatch(actions.authenticationFailure("Une erreur s'est produite, impossible de se connecter."));
                }
            });
    }
}

/** Send request to start a password reset process. */
export function sendPasswordResetRequest(email) {
    return simpleCallService(
		endpoints.RESET_PASSWORD_REQUEST_ENDPOINT,
		"POST",
		actions.passwordResetRequest,
		actions.passwordResetSuccess,
		actions.passwordResetFailure,
		email,
		false
	);
}

/** Send request for a new password. */
export function sendNewPasswordRequest(token, newPassword) {
    return simpleCallService(
		endpoints.RESET_PASSWORD_ENDPOINT + "?token=" + token,
		"POST",
		actions.newPasswordRequest,
		actions.newPasswordSuccess,
		actions.newPasswordFailure,
		newPassword,
		false
	);
}
