import React, { useState } from "react";

import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';

import { HelpButton, CourseYearGroupPicker } from '../../../shared/components';

const useStyles = makeStyles({
    root: {
        marginBottom: 15,
        textAlign: "left",
        paddingLeft: 10,
        display: "inline-flex",
        minWidth: "100%",
        alignItems: "center"
    }
});

export default function RealTimeAttendancyTopBar(props) {
    const classes = useStyles();
    const [currentViewMode, setCurrentViewMode] = useState(props.absenceViewModes[0]);
    const [currentStatusFilter, setCurrentStatusFilter] = useState(props.absenceStatusFilters[0]);

    const handleViewModeChange = (newViewMode) => {
        if (newViewMode !== currentViewMode) {
            setCurrentViewMode(newViewMode);
            props.displayNewView(newViewMode, currentStatusFilter);
        }
    }

    const handleStatusFilterChange = (newStatusFilter) => {
        if (newStatusFilter !== currentStatusFilter) {
            setCurrentStatusFilter(newStatusFilter);
            props.displayNewView(currentViewMode, newStatusFilter);
        }
    }

    const helperText = `Cette page permet de consulter et traiter les absences.
    Après avoir sélectionné une promotion, vous pouvez consulter les absences des étudiants
    sous 3 formats.`;

    return (
        <Paper className={classes.root}>
            <HelpButton
                text={helperText}
            />
            <div style={{ textAlign: "left" }}>
                {props.absenceViewModes.map((view, index) => {
                    return (
                        <Tooltip title={view.tooltip}>
                        <Button
                            variant="contained"
                            key={index}
                            color={currentViewMode === view ? "primary" : "lightGrey"}
                            onClick={() => handleViewModeChange(view)}
                            sx={{ marginLeft: 1 }}
                        >
                            {view.name}
                        </Button>
                        </Tooltip>
                    );
                })}
            </div>
            <div style={{ textAlign: "left", marginLeft: "20px" }}>
                <CourseYearGroupPicker
                    courseYearGroups={props.courseYearGroups}
                    handleCourseYearGroupSelection={props.handleCourseYearGroupSelection}
                />
            </div>
                <FormControl
                    variant="standard"
                    sx={{ minWidth: 250, marginLeft: "auto", paddingRight: 2 }}
                >
                    <InputLabel id="absences-status-filter-label">Afficher les absences</InputLabel>
                    <Select
                        labelId="absences-status-filter-label"
                        id="absences-status-filter"
                        value={currentStatusFilter}
                        label="Afficher les absences"
                        onChange={(e) => handleStatusFilterChange(e.target.value)}
                    >
                        {props.absenceStatusFilters.map((name, index) => {
                            return (
                                <MenuItem value={name} key={index}>
                                    {name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
        </Paper>
    );
}