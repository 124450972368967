import React, { useEffect, useState } from "react";
import { Views } from 'react-big-calendar';

import LoggedInLayout from "../../../shared/layouts/logged-in/LoggedInLayout";
import CourseSessionCalendar from "./CourseSessionCalendar";
import CourseSessionValidationTopBar from "./CourseSessionValidationTopBar";
import { SnackBarErrorMessageGroup } from '../../../shared/components';

const DEFAULT_VIEW = Views.WORK_WEEK;
const DEFAULT_DATE = new Date();

const getDefaultStartDate = (currentDate) => {
    const day = currentDate.getDay();
    if (DEFAULT_VIEW === Views.WORK_WEEK) {
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + (day === 0 ? -6 : 1) - day);
    }
    return null;
}

const getDefaultEndDate = (currentDate) => {
    const day = currentDate.getDay();
    if (DEFAULT_VIEW === Views.WORK_WEEK) {
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + (day === 0 ? 0 : 7) - day);
    }
    return null;
}

const addIndexAsKey = (courseSessions) => {
    if (!courseSessions) return [];
    return courseSessions.map((courseSessionContent, index) => ({ ...courseSessionContent, index: index }));
}

export default function CourseSessionValidationView(props) {
    const [dateRange, setDateRange] = useState({
        startDate: getDefaultStartDate(DEFAULT_DATE),
        endDate: getDefaultEndDate(DEFAULT_DATE)
    });
    const [events, setEvents] = useState([]);

    /* Fetching Available course year groups once */
    useEffect(() => {
        props.sendGetCourseYearGroupsRequest();
    }, [props.sendGetCourseYearGroupsRequest]);

    /* When course sessions are retrieved, copy them and add index property. */
    useEffect(() => {
        setEvents(addIndexAsKey(props.fetchedCourseSessions));
    }, [props.fetchedCourseSessions]);


    /* When new course year group is selected, fetch course sessions */
    const handleCourseYearGroupSelection = (newCourseYearGroup) => {
        props.selectCourseYearGroup(newCourseYearGroup);
        props.sendGetCourseSessionsRequest(newCourseYearGroup, dateRange.startDate, dateRange.endDate);
    };

    /* When course sessions are validated, send them to be saved */
    const handleValidateCourseSessions = (courseSessionsToSave) => {
        if (courseSessionsToSave) {
            props.sendCourseSessionsValidationRequest(props.selectedCourseYearGroup, courseSessionsToSave);
        }
    };

    /* When the calendar is changed to different dates, fetch course sessions */
    const handleDateRangeChange = (newStartDate, newEndDate) => {
        setDateRange({
            startDate: newStartDate,
            endDate: newEndDate
        });
        if (props.selectedCourseYearGroup) {
            props.sendGetCourseSessionsRequest(props.selectedCourseYearGroup, newStartDate, newEndDate);
        }
    };

    /* When a course session is marked as optional (or unmarked), update the event accordingly. */
    const handleOptionalChange = (courseSessionIndex, optionalValue) => {
        const newEvents = [...events];
        newEvents[courseSessionIndex].isOptional = optionalValue;
        setEvents(newEvents);
    };

    return (
        <LoggedInLayout isLoggedIn={props.isLoggedIn} username={props.username}>
            <CourseSessionValidationTopBar
                selectedCourseYearGroup={props.selectedCourseYearGroup}
                courseYearGroups={props.courseYearGroups}
                courseSessions={events}
                handleCourseYearGroupSelection={handleCourseYearGroupSelection}
                handleValidateCourseSessions={handleValidateCourseSessions}
            />
            <CourseSessionCalendar
                defaultView={DEFAULT_VIEW}
                defaultDate={DEFAULT_DATE}
                events={events}
                isLoading={props.isFetchingCourseSessions || props.isSendingCourseSessions}
                handleDateRangeChange={handleDateRangeChange}
                handleOptionalChange={handleOptionalChange}
            />
            <SnackBarErrorMessageGroup
                messages={[props.courseYearGroupsErrorMessage, props.courseSessionsErrorMessage, props.courseSessionsSaveErrorMessage]}
            />
        </LoggedInLayout>
    );
}