import React from "react";

import TopBarContainer from "./TopBarContainer";
import MainDrawer from "./MainDrawer";
import { redirectToLoginPage } from "../../utils/routing";

export default class LoggedInLayout extends React.Component {
    render() {
        if (!this.props.isLoggedIn) {
            redirectToLoginPage();
            return null;
        } else {
            return (
                <div className="itii-logged-in" style={{display: "flex"}}>
                    <TopBarContainer />
                    <MainDrawer username={this.props.username} />
                    <main style={{flexGrow:1, padding:"88px 24px 24px 24px"}}>
                        {this.props.children}
                    </main>
                </div>
            );
        }
    }
}
