import { connect } from "react-redux";

import ResetPasswordView from "./ResetPasswordView";
import { sendNewPasswordRequest } from "../../../shared/redux/services";

const mapStateToProps = state => {
    return {
        passwordResetIsLoading: state.PasswordResetReducer.isFetching,
        passwordResetSuccessful: state.PasswordResetReducer.isSuccessful,
        passwordResetErrorMessage: state.PasswordResetReducer.errorMessage
    };
};

function mapDispatchToProps(dispatch) {
    return {
        sendResetPassword: (token, newPassword) => {
            dispatch(sendNewPasswordRequest(token, newPassword));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordView);