import * as React from 'react';
import moment from 'moment';
import 'moment/locale/fr';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepConnector from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';

import WatchLater from '@mui/icons-material/WatchLater';

export default function TimeSpan(props) {
	return (
		<Stepper style={{ padding: '0' }} orientation='vertical' connector={<StepConnector />} >
			<Step completed={true}>
				<StepLabel icon={<WatchLater color={"secondary"} />}>{formatDate(props.startTime)}</StepLabel>
			</Step>
			<Step completed={true}>
				<StepLabel icon={<WatchLater color={"secondary"} />}>{formatDate(props.endTime)}</StepLabel>
			</Step>
		</Stepper>
	);
}

const formatDate = (date) => {
	return moment(date).format("dddd DD MMM YYYY  -  HH:mm");
}