import { authenticationFailure } from "../../../../shared/redux/actions";
import { fetchWithTimeOut, callService } from "../../../../shared/utils/networking";
import { MAIN_SERVER_URL } from "../../../../shared/config/index.js";

import { sendDataRequest, sendDataSuccess, sendDataFailure,
    fetchDataRequest, fetchDataSuccess, fetchDataFailure } from "./DataManagementActions";

export const DATA_REQUESTS = new Map([
    ["Ecole", { method: "POST", endpoint: "data-management/school" }],
    ["Formation", { method: "POST", endpoint: "data-management/course" }],
    ["Promotion", { method: "POST", endpoint: "data-management/course-year-group" }],
    ["Etudiant", { method: "POST", endpoint: "data-management/student" }],
    ["Etudiants", { method: "POST", endpoint: "data-management/students" }],
    ["Groupe", { method: "POST", endpoint: "data-management/student-group" }]
]);

export const DATA_FETCH = new Map([
    ["Ecole", {
        method: "GET",
        endpoint: "data-management/schools",
        columns: [
            { title: "Nom de l'école", field: "name" },
            { title: 'Formations', field: 'courses' },
            { title: 'Adresse postale', field: 'postalAddress' }
        ],
        resultToData: (result) => {
            const postalAddress = (result.postalAddress !== null && result.postalAddress !== undefined) ? [result.postalAddress.addressNumber, result.postalAddress.streetName, result.postalAddress.postalCode, result.postalAddress.cityName, result.postalAddress.country].join(" ").trim() : "";
            return ({
                name: result.schoolName,
                courses: result.coursesName.join(", "),
                postalAddress: postalAddress,
            });
        }
    }
    ],
    ["Formation", {
        method: "GET",
        endpoint: "data-management/courses",
        columns: [
            { title: "Formation", field: "courseName" },
            { title: "Ecoles", field: "schoolName" },
            { title: "Promotions", field: "courseYearGroups" }
        ],
        resultToData: (result) => {
            return ({
                courseName: result.courseName,
                schoolName: result.schoolName,
                courseYearGroups: (result.courseYearGroups !== undefined && result.courseYearGroups !== null) ? result.courseYearGroups.join(", ") : ""
            });
        }
    }
    ],
    ["Promotion", {
        method: "GET",
        endpoint: "data-management/course-year-groups",
        columns: [
            { title: "Promotions", field: "courseYearGroupName" },
            { title: "Promotions Nom Complet", field: "courseYearGroupCompleteName" },
            { title: "Formation", field: "courseName" },
            { title: "Année", field: "courseYearGroupGraduationYear" },
            { title: "Groupe étudiants", field: "studentGroupList" },
            { title: "URL ICS", field: "urlForCourseSessionsExtraction" }
        ],
        resultToData: (result) => {
            return ({
                courseYearGroupName: result.courseYearGroupName,
                courseYearGroupCompleteName: result.courseYearGroupCompleteName,
                courseName: result.courseName,
                courseYearGroupGraduationYear: result.courseYearGroupGraduationYear,

                studentGroupList: (result.studentGroupList !== null && result.studentGroupList !== undefined) ? result.studentGroupList.join(", ") : "",
                urlForCourseSessionsExtraction: (result.urlForCourseSessionsExtraction !== null && result.urlForCourseSessionsExtraction !== undefined) ? result.urlForCourseSessionsExtraction : ""
            });
        }
    }
    ],
    ["Etudiant", {
        method: "GET",
        endpoint: "data-management/students ",
        columns: [
            { title: "Prénom", field: "studentFirstName" },
            { title: "Nom", field: "studentLastName" },
            { title: "Adresse email", field: "studentEmailAddress" },
            { title: "Identifiants Cartes", field: "idCards" },
            { title: "Promotion", field: "courseYearGroup" },
            { title: "Ecole", field: "school" }
        ],
        resultToData: (result) => {
            return ({
                studentFirstName: result.studentFirstName,
                studentLastName: result.studentLastName,
                studentEmailAddress: result.studentEmailAddress,
                idCards: result.idCards.join(", "),
                courseYearGroup: result.courseYearGroup,
                school: result.school
            }); //TODO TO SEE INFORMATION FROM COMPANY
        }
    }
    ]
]);

/* Send request to get data {student, course, school, courseYearGroup } to visualise data */
export function fetchData(requestType) {
    return function (dispatch) {
        dispatch(fetchDataRequest());

        if (!DATA_FETCH.get(requestType)) {
            dispatch(fetchDataFailure("La requête de ce type est inconnue. Veuillez contacter un administrateur.", requestType));
        }

        const url = MAIN_SERVER_URL + DATA_FETCH.get(requestType).endpoint;
        const token = localStorage.token;

        return fetchWithTimeOut(url, { method: DATA_FETCH.get(requestType).method, headers: { "Authorization": token } })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw response;
                }
            })
            .then(result => {
                dispatch(fetchDataSuccess(result, requestType));
            })
            .catch(error => {
                if (error.status === 403) {
                    localStorage.clear();
                    dispatch(authenticationFailure("Votre session a expiré, veuillez vous reconnecter."));
                } else if (error.status === 400) {
                    error.text().then(errorMessage => dispatch(fetchDataFailure(errorMessage)));
                } else {
                    dispatch(fetchDataFailure("Une erreur est survenue. Veuillez contacter un administrateur."));
                }
            });
    }
}

export function sendData(requestType, body) {
    if (!DATA_REQUESTS.get(requestType)) {
        return (dispatch) => { dispatch(sendDataRequest()); dispatch(sendDataFailure("La requête de ce type est inconnue. Veuillez contacter un administrateur.")); };
    }

    const url = MAIN_SERVER_URL + DATA_REQUESTS.get(requestType).endpoint;
    const options = { method: DATA_REQUESTS.get(requestType).method, headers: { "Authorization": localStorage.token, "Content-Type": "application/json" }, body: body };

    return callService(url, options, sendDataRequest, sendDataFailure, sendDataSuccess, false);
}