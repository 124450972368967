import React from "react";
import MaterialTable from 'material-table';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SchoolIcon from '@material-ui/icons/School';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from '@material-ui/icons/Assignment';

import { DATA_FETCH } from "./redux/DataManagementServices";

export default class DataView extends React.Component {

  handleTabsChange = (event,requestType) => {
    this.props.fetchDataRequest(requestType);
  };

  componentDidMount() {
    this.props.fetchDataRequest("Ecole");
  }

  render() {
    const result = this.props.result ;
    const requestType = this.props.requestType ;

    var data=[];
    var columns=[];

    if(requestType !== undefined){
      data = result.map(result => DATA_FETCH.get(requestType).resultToData(result));
      columns = DATA_FETCH.get(requestType).columns ;
    }

      return (
        <div>
          <Tabs value={requestType || "Ecole"} onChange={this.handleTabsChange}>
            <Tab label="Données écoles" value="Ecole" icon={<SchoolIcon />} />
            <Tab label="Données formations" value="Formation" icon={<AssignmentIcon />} />
            <Tab label="Données promotions" value="Promotion" icon={<PeopleIcon />} />
            <Tab label="Données étudiants" value="Etudiant" icon={<PersonIcon />} />
          </Tabs>
          <MaterialTable
            title={requestType}
            columns={columns}
            data={data}
            />
        </div>
      );
  }
}
