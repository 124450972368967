import * as types from "../actions";

export const initialState = {
    isLoggedIn: false,
    username: undefined,
    password: undefined,
    isLoading: false
};

export const LoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.AUTHENTICATION_REQUEST:
            return Object.assign({}, state, {
                isLoggedIn: false,
                username: action.username,
                password: action.password,
                errorMessage: undefined,
                isLoading: true
            });
        case types.AUTHENTICATION_SUCCESS:
            return Object.assign({}, state, {
                isLoggedIn: true,
                username: action.username,
                password: undefined,
                errorMessage: undefined,
                isLoading: false
            });
        case types.AUTHENTICATION_FAILURE:
            return Object.assign({}, state, {
                isLoggedIn: false,
                username: undefined,
                password: undefined,
                errorMessage: action.errorMessage,
                isLoading: false
            });
        case types.AUTH_TOKEN_TIMEOUT:
            return Object.assign({}, state, {
                isLoggedIn: false,
                username: undefined,
                password: undefined,
                isLoading: false
            });
        case types.LOG_OUT:
            return Object.assign({}, state, {
                isLoggedIn: false,
                username: undefined,
                password: undefined,
                isLoading:  false
            });
        default:
            return state;
    }
}
