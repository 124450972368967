import * as actions from "../actions";
import * as endpoints from "../api";
import { simpleCallService } from "../../utils/networking";

/** Send request to receive course year groups available for the current user */
export function fetchCourseYearGroups() {
	return simpleCallService(
		endpoints.ACCESSIBLE_COURSE_YEAR_GROUPS_ENDPOINT,
		"GET",
		actions.fetchCourseYearGroupsRequest,
		actions.fetchCourseYearGroupsSuccess,
		actions.fetchCourseYearGroupsFailure,
		null,
		true
	);
}

/** Send request to get students for a given course year group  */
export function fetchStudentsOfCourseYearGroup(courseYearGroup) {
	return simpleCallService(
		endpoints.STUDENTS_OF_COURSE_YEAR_GROUP_ENDPOINT + "?course-year-group=" + courseYearGroup,
		"GET",
		actions.fetchStudentsRequest,
		actions.fetchStudentsSuccess,
		actions.fetchStudentsFailure,
		null,
		true
	);
}
