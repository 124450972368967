import React from "react";

import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { ActionableDialog } from '../../../shared/components';

/*
courseSessionsToSave={courseSessionsToSave}*/

export function CourseSessionValidationModal(props) {

    const getContent = () => {
        return {
            title: "Valider les cours affichés",
            text: getText(),
            action: getAction()
        }
    };

    const getText = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', maxWidth: '500px' }}>
                {props.displayBlockingErrorWarning
                    ? <Alert severity="warning">Les cours affichés en rouge ne peuvent pas être sauvegardés. Pour en savoir plus, vous pouvez cliquer sur ces cours pour en afficher la raison.</Alert>
                    : null
                }

                <Typography>
                    Vous êtes sur le point de valider les cours suivants : 
                </Typography>
                {getCourseSessionsTable()}
                <Typography>
                    Veuillez vous assurer que ceux-ci ont bien eu lieu avant de valider.
                </Typography>
            </div>
        );
    };

    const getCourseSessionsTable = () => {
        const events = props.courseSessionsToSave.sort((a, b) => a.start > b.start);
        return (
            <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Cours</TableCell>
                    <TableCell>Date</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {events.map((event, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">{event.title}</TableCell>
                            <TableCell>{formatDate(event.start)}</TableCell>
                        </TableRow>
                ))}
            </TableBody>
        </Table>
        );
    }

    const formatDate = (date) => {
        return date.getFullYear() + "-" +
            (date.getMonth() >= 9 ? "" : "0") +
            (date.getMonth() + 1) + "-" +
            (date.getDate() > 9 ? "" : "0") + date.getDate();
    };

    const getAction = () => {
        return (
            <React.Fragment>
                <Button onClick={props.onClose}>Annuler</Button>
                <Button onClick={props.onSavingCourseSessions}>Enregistrer</Button>
            </React.Fragment>
        )
    };

    return (
        <ActionableDialog
            open={props.open}
            onClose={props.onClose}
            content={getContent()}
        />
    );
}
