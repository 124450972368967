import { connect } from "react-redux";

import CardManagementPageView from "./CardManagementPageView";
import { fetchCourseYearGroups, fetchCardDetailsOfCourseYearGroup, fetchCardOwner, dissociateCard, reaffectCard, affectCard } from "../../../shared/redux/services";

const mapStateToProps = state => {
    return {
        isLoggedIn: state.LoginReducer.isLoggedIn,
        username: state.LoginReducer.username,
        courseYearGroups: state.SharedReducer.courseYearGroups,
        cardDetails: state.CardDetailsReducer.cardDetails,
        cardOwner: state.CardDetailsReducer.cardOwner,
        isLoading: state.StudentsAttendanceStatsReducer.isLoading,
        errorMessage: state.StudentsAttendanceStatsReducer.errorMessage,
        cardDetailsErrorMessage: state.CardDetailsReducer.errorMessage
    };
};

function mapDispatchToProps(dispatch) {
    return {
        sendCourseYearGroupsRequest: () => {
            dispatch(fetchCourseYearGroups());
        },
        sendCardDetailsRequest: (courseYearGroup) => {
            dispatch(fetchCardDetailsOfCourseYearGroup(courseYearGroup));
        },
        sendCardOwnerRequest: (cardId) => {
            dispatch(fetchCardOwner(cardId));
        },
        sendDissociateCardRequest: (cardId) => {
            dispatch(dissociateCard(cardId));
        },
        sendReaffectCardRequest: (cardId, attenderId) => {
            dispatch(reaffectCard(cardId, attenderId));
        },
        sendAffectCardRequest: (cardId, attenderId) => {
            dispatch(affectCard(cardId, attenderId));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CardManagementPageView);