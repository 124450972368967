import { connect } from "react-redux";

import { fetchCourseYearGroups, fetchStudentsAttendanceStats, fetchStudentCourseSessions } from "../../../shared/redux/services";
import StudentsAttendanceMonitoringView from "./StudentsAttendanceMonitoringView";

const mapStateToProps = state => {
    return {
        isLoggedIn: state.LoginReducer.isLoggedIn,
        username: state.LoginReducer.username,
        courseYearGroups: state.SharedReducer.courseYearGroups,
        isLoading: state.StudentsAttendanceStatsReducer.isLoading,
        errorMessage: state.StudentsAttendanceStatsReducer.errorMessage,
        stats: state.StudentsAttendanceStatsReducer.stats || [],
        courseSessions: state.CourseSessionsReducer.studentCourseSessions || [],
        courseSessionErrorMessage: state.CourseSessionsReducer.errorMessage
    };
};

function mapDispatchToProps(dispatch) {
    return {
        sendCourseYearGroupsRequest: () => {
            dispatch(fetchCourseYearGroups());
        },
        sendCourseYearGroupStatsRequest: (courseYearGroup, startTime, endTime) => {
            if (!startTime || !endTime) return;
            dispatch(fetchStudentsAttendanceStats(courseYearGroup, startTime, endTime));
        },
        sendStudentCourseSessionsRequest: (studentId, startTime, endTime) => {
            if (!startTime || !endTime) return;
            dispatch(fetchStudentCourseSessions(studentId, startTime.toDate(), endTime.toDate()));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentsAttendanceMonitoringView);
