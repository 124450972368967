import * as React from 'react';
import frLocale from 'date-fns/locale/fr';

import { makeStyles } from '@mui/styles';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';

import { HelpButton, CourseYearGroupPicker, SnackBarErrorMessageGroup } from '../../../shared/components';

const useStyles = makeStyles({
    root: {
        marginBottom: 15,
        textAlign: "left",
        paddingLeft: 10,
        display: "inline-flex",
        minWidth: "100%",
        alignItems: "center"
    },
    rightAlign: {
        marginLeft: "auto",
        paddingRight: 10
    }
});

export default function AttendanceCertificatesTopBar(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState([null, null]);

    const helperText = `Cette page permet de générer des attestations.
    En sélectionnant une promotion, vous pouvez voir les attestations à générer, celles à envoyer et celles déjà transmises.`;

    return (
        <Paper className={classes.root}>
            <HelpButton
                text={helperText}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
                <DateRangePicker
                    
                    startText="Début de période"
                    endText="Fin de période"
                    value={value}
                    onChange={(newValue) => {
                        setValue(newValue);
                    }}
                    renderInput={(startProps, endProps) => (
                        <React.Fragment>
                            <TextField variant="filled" {...startProps} />
                            <Box sx={{ mx: 2 }}> à </Box>
                            <TextField variant="filled" {...endProps} />
                        </React.Fragment>
                    )}
                />
            </LocalizationProvider>
            <CourseYearGroupPicker
                courseYearGroups={props.courseYearGroups}
                handleCourseYearGroupSelection={props.handleCourseYearGroupSelection}
                disabled={value[0] == null || value[1] == null}
            />
            <SnackBarErrorMessageGroup
                messages={[]}
            />
        </Paper>
    );
}