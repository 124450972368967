import React from "react";
import moment from 'moment';

import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { SimpleDialog } from '../../../shared/components';

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function AttendanceCreationModal(props) {
    const theme = useTheme();
    const [dateTime, setDateTime] = React.useState(moment());
    const [cleanUpMessage, setCleanUpMessage] = React.useState(false);
    const [selectedStudents, setSelectedStudents] = React.useState([]);

    const sortedStudentsNames = props.students
        .map(student => student.studentLastName + " " + student.studentFirstName)
        .sort();
    const studentsMap = new Map(props.students
        .map(student => [student.studentLastName + " " + student.studentFirstName, student]));

    const handleSelectedStudentsChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedStudents(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleDateTimeChange = (newValue) => {
        setDateTime(newValue);
        setCleanUpMessage(true);
    }

    const handleCreateAttendancy = () => {
        setCleanUpMessage(false);

        const studentIds = [];
        selectedStudents.forEach(studentName => {
            studentIds.push(studentsMap.get(studentName).studentId);
        });
        props.sendCreateAttendanceRecordRequest(studentIds, dateTime);
    }

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedStudents(sortedStudentsNames);
        } else {
            setSelectedStudents([]);
        }
    }

    const getModalContent = (sortedStudentsNames) => {
        const content = (
            <div style={{ margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
                <Typography>Veuillez sélectionner l'étudiant pour lequel vous souhaitez créer un badgeage, ainsi que la date du badgeage, puis cliquez sur "Créer le badgeage".</Typography>
                <FormControl sx={{ m: 1, width: 300, gap: 3 }}>
                    <InputLabel id="students-select-label">Etudiants</InputLabel>
                    <Select
                        labelId="students-select-label"
                        id="students-select"
                        multiple
                        value={selectedStudents}
                        onChange={handleSelectedStudentsChange}
                        input={<OutlinedInput id="students-select" label="Chip" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {sortedStudentsNames.map((name) => (
                            <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, selectedStudents, theme)}
                            >
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormControlLabel control={<Checkbox onChange={handleSelectAll} />} label="Sélectionner la promotion" />
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateTimePicker
                            label="Date de badgeage"
                            value={dateTime}
                            onChange={handleDateTimeChange}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </FormControl>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    disabled={!selectedStudents}
                    onClick={handleCreateAttendancy}
                    loading={props.isCreatingAttendanceRecord}
                >
                    Créer le badgeage
                </LoadingButton>
                {!cleanUpMessage && props.attendanceRecordCreated
                    ? <Alert severity="success">Le badgeage a bien été enregistré.</Alert>
                    : null
                }
                {!cleanUpMessage && props.attendanceRecordCreationErrorMessage
                    ? <Alert severity="error">Une erreur s'est produite, veuillez contacter un administrateur à admin@alumneo.fr.</Alert>
                    : null
                }
            </div>
        );
        return {
            title: "Création d'un badgeage",
            text: content
        }
    }

    return (
        <SimpleDialog
            open={props.open}
            onClose={props.onClose}
            content={getModalContent(sortedStudentsNames)}
        />
    );
}