import React, { useState } from "react";
import { Navigate } from "react-router-dom";

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';

import LoggedOutLayout from "../../../shared/layouts/logged-out/LoggedOutLayout";

import '../../../shared/styles/login.css';
import ResetPasswordRequestDialog from "./ResetPasswordRequestDialog";

export default function Login(props) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [displayForgottenPasswordModal, setDisplayForgottenPasswordModal] = useState(false);

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        props.sendAuthenticationRequest(username, password);
    }

    const handleForgottenPasswordClick = (e) => {
        setDisplayForgottenPasswordModal(!displayForgottenPasswordModal);
    }
    
    if (props.isLoggedIn) {
        if (localStorage.features && localStorage.features === "STUDENT_ACCESS") {
            return <Navigate to={{ pathname: '/student', state: { from: props.location } }} />;
        } else {
            return <Navigate to={{ pathname: '/', state: { from: props.location } }} />;
        }
    } else {
        return (
            <LoggedOutLayout isLoggedIn={props.isLoggedIn}>
                <Container component="main" maxWidth="xs">
                    <div className="login-form">
                        <Box fontSize="h6.fontSize" m={1}>
                            Connexion au site de l'ITII PdL
                        </Box>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                id="username"
                                label="Nom d'utilisateur ou adresse e-mail"
                                value={username}
                                onChange={handleUsernameChange}
                                margin="normal"
                                variant="outlined"
                                type="text"
                                required
                                fullWidth
                                autoFocus
                                error={props.errorMessage ? true : false}
                                disabled={props.isLoading}
                            />
                            <TextField
                                id="password"
                                label="Mot de passe"
                                value={password}
                                onChange={handlePasswordChange}
                                margin="normal"
                                variant="outlined"
                                type="password"
                                required
                                fullWidth
                                error={props.errorMessage ? true : false}
                                disabled={props.isLoading}
                            />
                            <Link onClick={handleForgottenPasswordClick} >
                                Cliquez ici si vous avez oublié votre mot de passe.
                            </Link>
                            <div className="login-submit">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    fullWidth
                                    disabled={props.isLoading}
                                >
                                    Se connecter
                                </Button>
                            </div>
                        </form>
                        {props.isLoading ?
                            <div style={{ position: "relative", top: "-15vh" }}>
                                <CircularProgress />
                            </div>
                            : null
                        }
                        {props.errorMessage ? <div><p>{props.errorMessage}</p></div> : null}
                        {displayForgottenPasswordModal ? 
                                <ResetPasswordRequestDialog
                                    open={displayForgottenPasswordModal}
                                    onClose={handleForgottenPasswordClick}
                                    isSendingRequest={props.passwordResetIsLoading}
                                    sendPasswordResetRequest={props.sendPasswordResetRequest}
                                    passwordResetRequested={props.passwordResetSuccessful}
                                    passwordResetErrorMessage={props.passwordResetErrorMessage}
                                />
                            : null}
                    </div>
                </Container>
            </LoggedOutLayout>
        )
    }
}
